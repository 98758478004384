import React from "react";
import { useDrag } from "react-dnd";
import { Card } from "primereact/card";
import { Media } from "models/Media";
import { MyNumberGreeting } from "hooks/services/useGreetingService";

interface GreetingCardProps {
  greeting: Media;
  setMyNumberGreeting: ({
    myNumber,
    url,
  }: {
    myNumber: string;
    url: string;
  }) => void;
}

export const GreetingCard: React.FC<GreetingCardProps> = ({
  greeting,
  setMyNumberGreeting,
}) => {
  const [, drag] = useDrag(() => ({
    type: "greeting",
    item: greeting,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<MyNumberGreeting>();
      if (dropResult?.myNumber) {
        setMyNumberGreeting({
          myNumber: dropResult.myNumber.number,
          url: item.url!,
        });
      }
    },
  }));

  return (
    <div ref={drag}>
      <Card className="greeting-card">
        <label>{greeting.description}</label>
        <audio controls={true} src={greeting.url} />
      </Card>
    </div>
  );
};
