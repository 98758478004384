import { storageKeys } from "const/storage-keys";
import { environment } from "environments";
import { Token, anonymousToken } from "models/Token";

const partSize = 100000;

function getToken(): Token {
  return getStorageItem(storageKeys.auth.token) || anonymousToken;
}

export const getStorageItem = <T>(
  key: string,
  storage: Storage = localStorage
): T | undefined => {
  let partIndex = 0;
  let storageValue = "";
  let partContent: string;
  do {
    partContent = storage.getItem(getPartKey(key, partIndex)) || "";
    storageValue += partContent;
    partIndex++;
  } while (partContent);
  return storageValue ? JSON.parse(storageValue) : undefined;
};

export const setStorageItem = <T>(
  key: string,
  data: T,
  storage: Storage = localStorage
): void => {
  if (key.startsWith(environment.storagePrefix) && !getToken().accessToken) {
    return;
  }
  const storageValue = JSON.stringify(data);
  if (storageValue === undefined) {
    if (getStorageItem(key)) {
      storage.removeItem(key);
    }
    return;
  }
  if (JSON.stringify(getStorageItem(key)) === storageValue) {
    return;
  }
  const partCount = Math.ceil(storageValue.length / partSize);
  for (let partIndex = 0; partIndex < partCount; partIndex++) {
    const partContent = storageValue.substring(
      partIndex * partSize,
      (partIndex + 1) * partSize
    );
    storage.setItem(getPartKey(key, partIndex), partContent);
  }
  let prevPartIndex = partCount;
  while (localStorage.getItem(getPartKey(key, prevPartIndex))) {
    storage.removeItem(getPartKey(key, prevPartIndex));
    prevPartIndex++;
  }
};

const getPartKey = (key: string, partIndex: number): string =>
  partIndex ? `${key}__part${partIndex}` : key;
