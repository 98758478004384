import React, { useRef } from "react";
import { environment } from "environments";
import "./Map.sass";
import { useAuthService } from "hooks/services/useAuthService";
import CryptoJS from "crypto-js";
import { useMapService } from "hooks/services/useMapService";

export const Map: React.FC = () => {
  const auth = useAuthService();
  const mapService = useMapService();

  const mapRef = useRef<HTMLIFrameElement>(null);

  const { token, customerNumber } = auth;
  // const encrypted = encodeURI(JSON.stringify({ ...token, customerNumber }));
  const { selectedLocation } = mapService;
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify({ ...token, customerNumber, selectedLocation }),
    environment.map.secret
  ).toString();
  const url = new URL(environment.map.url);
  url.searchParams.append("t", encrypted);

  return (
    <div className="map-component auto-flex">
      <iframe
        className="auto-flex"
        ref={mapRef}
        src={url.toString()}
        title="Map"
        allow="geolocation"
      />
    </div>
  );
};
