import { matchPath, useLocation, Location, useNavigate } from "react-router";
import { storageKeys } from "const/storage-keys";
import { getStorageItem, setStorageItem } from "helpers/storage";
import { Message } from "models/Message";
import { AppMode, Page, modes, pageInfo } from "models/navigation";
import { useService, Service, UpdateDispatcher } from "./useService";
import { environment } from "environments";
import { AuthService, useAuthService } from "./useAuthService";
import { MyNumberService, useMyNumberService } from "./useMyNumberService";
import { Feature } from "models/MyNumber";
import { useCallService } from "./useCallService";

const ud: UpdateDispatcher = new Set();

export function useNavigationService(): NavigationService {
  const auth = useAuthService();
  const myNumberService = useMyNumberService();
  const callService = useCallService();
  const hasCall = Boolean(callService.currentCall);
  const location = useLocation();
  const navigate = useNavigate();

  return useService(
    ud,
    NavigationService,
    { auth, myNumberService },
    { hasCall, location, navigate }
  );
}

export class NavigationService extends Service {
  public static serviceName = "NavigationService";

  public modes: AppMode[] = []; // available modes only
  public pagesByMode: Partial<Record<AppMode, Page[]>> = {}; // availables pages only, can be changed over time

  private currentMode: AppMode | undefined =
    getStorageItem(storageKeys.navigation.mode) || undefined;
  private currentPageByMode: Record<AppMode, Page> =
    getStorageItem(storageKeys.navigation.page) ||
    ({} as Record<AppMode, Page>);

  private hasCall: boolean = false;
  private pageBeforeCall: { mode: AppMode; page: Page } | null = null;
  private location: Location = {} as Location;
  private navigate: (path: string) => void = () => {};

  private readonly auth: AuthService;
  private readonly myNumberService: MyNumberService;

  constructor({
    auth,
    myNumberService,
  }: {
    auth: AuthService;
    myNumberService: MyNumberService;
  }) {
    super({ auth, myNumberService });
    this.auth = auth;
    this.myNumberService = myNumberService;
  }

  public async init(): Promise<void> {
    this.updateAvailableModesPages();
  }

  public async onDependentServiceUpdate({
    serviceName,
  }: {
    serviceName: string;
  }) {
    switch (serviceName) {
      case AuthService.name:
      case MyNumberService.name: {
        console.log("Navigation Service: onDependentServiceUpdate", {
          serviceName,
        });
        this.updateAvailableModesPages();
        break;
      }
    }
  }

  public onChange({
    hasCall,
    location,
    navigate,
  }: {
    hasCall: boolean;
    location: Location;
    navigate: (path: string) => void;
  }): void {
    console.log("Navigation Service: onChange", {
      hasCall,
      location,
      navigate,
    });
    if (this.hasCall !== hasCall) {
      let newMode: AppMode | undefined;
      let newPage: Page | undefined;
      if (hasCall) {
        this.pageBeforeCall =
          this.mode && this.page ? { mode: this.mode, page: this.page } : null;
        newMode = AppMode.calls;
        newPage = Page.activeCall;
      } else {
        newMode = this.pageBeforeCall?.mode || AppMode.calls;
        newPage = this.pageBeforeCall?.page || Page.callHistory;
        this.pageBeforeCall = null;
      }
      this.hasCall = hasCall;
      this.updateAvailableModesPages();
      this.mode = newMode;
      this.page = newPage;
      this.update();
    }
    this.location = location;
    this.navigate = navigate;
  }

  public update() {
    setStorageItem(storageKeys.navigation.mode, this.currentMode);
    setStorageItem(storageKeys.navigation.page, this.currentPageByMode);
    super.update();
    this.navigateIfNeed();
  }

  public get mode(): AppMode | undefined {
    return this.currentMode;
  }
  public set mode(value: AppMode | undefined) {
    if (
      !value ||
      this.currentMode === value ||
      !this.modes.includes(value) ||
      !this.pagesByMode[value]?.length
    ) {
      return;
    }
    this.currentMode = value;
    if (!this.page || !this.pagesByMode[value]?.includes(this.page)) {
      this.page = this.pagesByMode[value]![0];
    }
    this.update();
  }

  public get page(): Page | undefined {
    const { mode } = this;
    return (mode && this.currentPageByMode[mode]) || undefined;
  }
  public set page(value: Page | undefined) {
    const { mode } = this;
    if (
      !mode ||
      !value ||
      this.currentPageByMode[mode] === value ||
      !(this.pagesByMode[mode] || []).includes(value)
    ) {
      return;
    }
    this.currentPageByMode[mode] = value;
    this.update();
  }

  private navigateIfNeed() {
    const { page } = this;
    if (!page) {
      return;
    }
    const route = pageInfo[page].route;
    if (!matchPath(pageInfo[page].route, this.location.pathname)) {
      this.navigate(route);
    }
  }

  private updateAvailableModesPages() {
    const has = this.myNumberService.hasFeature;
    if (!this.auth.authenticated) {
      this.pagesByMode = {};
    } else if (this.auth.callRecording) {
      this.pagesByMode = {
        [AppMode.callRecodings]: [
          Page.callRecordingTable,
          Page.callRecordingSettings,
        ],
      };
    } else if (this.auth.callForwarding) {
      this.pagesByMode = {
        [AppMode.callForwarding]: [
          Page.callForwarding,
          Page.callRecordingSettings,
        ],
      };
    } else if (this.auth.voicemail) {
      this.pagesByMode = {
        [AppMode.voicemails]: [Page.voicemails],
      };
    } else if (this.auth.fax) {
      this.pagesByMode = {
        [AppMode.fax]: [Page.fax],
      };
    } else {
      this.pagesByMode = {
        [AppMode.home]: [Page.home, Page.activity, Page.config, Page.tools],
        [AppMode.chats]:
          has(Feature.sms) ||
          has(Feature.callRecording) ||
          has(Feature.mms) ||
          has(Feature.sms) ||
          has(Feature.voicemails) ||
          has(Feature.fax)
            ? [Page.chats]
            : [],
        [AppMode.calls]: [
          ...(has(Feature.calls)
            ? [
                Page.callHistory,
                Page.dialer,
                ...(this.hasCall ? [Page.activeCall] : []),
              ]
            : []),
          ...(has(Feature.callForwarding) ? [Page.callForwarding] : []),
          ...(has(Feature.callRecording) ? [Page.callRecordings] : []),
          ...(has(Feature.callProcedures) ? [Page.callProcedures] : []),
        ],
        [AppMode.sms]:
          has(Feature.sms) || has(Feature.mms)
            ? [
                Page.sms,
                Page.smsTemplates,
                Page.smsList,
                Page.autoResponder,
                Page.gallery,
              ]
            : [],
        [AppMode.voicemails]: has(Feature.voicemails)
          ? [Page.voicemails, Page.greetings]
          : [],
        [AppMode.fax]: has(Feature.fax) ? [Page.fax] : [],
        [AppMode.map]: environment.map?.url ? [Page.map] : [],
      };
    }
    this.modes = modes.filter(
      (mode: AppMode) => (this.pagesByMode[mode] || []).length > 0
    );

    // update current mode & page if needed
    if (!this.currentMode || !this.modes.includes(this.currentMode)) {
      this.mode = this.modes[0]; // it updates this.currentMode
    }

    for (const mode of this.modes) {
      if (!this.currentPageByMode[mode]) {
        let currentPage = (this.pagesByMode[mode] || [])[0];
        if (currentPage === Page.callHistory) {
          const emptyCallHistory = !getStorageItem<Message[]>(
            storageKeys.calls.messages
          )?.length;
          if (
            emptyCallHistory &&
            (this.pagesByMode[mode] || []).includes(Page.dialer)
          ) {
            currentPage = Page.dialer;
          }
        }
        this.currentPageByMode[mode] = currentPage;
      }
    }

    console.log("Navigation Service: updateAvailableModesPages", {
      authenticated: this.auth.authenticated,
      callRecording: this.auth.callRecording,
      callForwarding: this.auth.callForwarding,
      modes: this.modes,
      pagesByMode: this.pagesByMode,
      currentPageByMode: this.currentPageByMode,
      currentMode: this.mode,
      currentPage: this.page,
    });

    this.update();
  }
}
