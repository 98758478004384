import { Id, PhoneNumber } from "models/common";
import {
  Direction,
  Message,
  MessageType,
  allMessageTypes,
} from "models/Message";
import { ContactService, useContactService } from "./useContactService";
import { CallService, useCallService } from "./useCallService";
import { SmsService, useSmsService } from "./useSmsService";
import { useVoicemailService, VoicemailService } from "./useVoicemailService";
import { sortByAtDescFunc } from "helpers/collection";
import { MmsService, useMmsService } from "./useMmsService";
import { FaxService, useFaxService } from "./useFaxService";
import { DraftService, useDraftService } from "./useDraftService";
import {
  CallRecordingService,
  useCallRecordingService,
} from "./useCallRecordingService";
import { useService, Service, UpdateDispatcher } from "./useService";
import { Contact } from "models/Contact";
import { AuthService, useAuthService } from "./useAuthService";

const ud: UpdateDispatcher = new Set();

export function useChatService(): ChatService {
  const authService = useAuthService();
  const contactService = useContactService();
  const callService = useCallService();
  const callRecordingService = useCallRecordingService();
  const smsService = useSmsService();
  const mmsService = useMmsService();
  const voicemailService = useVoicemailService();
  const faxService = useFaxService();
  const draftService = useDraftService();
  return useService(ud, ChatService, {
    authService,
    contactService,
    callService,
    callRecordingService,
    smsService,
    mmsService,
    voicemailService,
    faxService,
    draftService,
  });
}

// Chat service is Call service + SMS service + Voicemail service, it provides messagesByContactId where messages are retrieved and merged from different services
export class ChatService extends Service {
  public static serviceName = "ChatService";

  // WARNING: please reset messagesByContactId completely to {} on any update from other services
  private messagesByContactId: Record<Id, Promise<Message[]>> = {}; // key is contactId; message array is sorted `at desc`

  private readonly authService: AuthService;
  private readonly contactService: ContactService;
  private readonly callService: CallService;
  private readonly callRecordingService: CallRecordingService;
  private readonly smsService: SmsService;
  private readonly mmsService: MmsService;
  private readonly voicemailService: VoicemailService;
  private readonly faxService: FaxService;
  private readonly draftService: DraftService;

  constructor({
    authService,
    contactService,
    callService,
    callRecordingService,
    smsService,
    mmsService,
    voicemailService,
    faxService,
    draftService,
  }: {
    authService: AuthService;
    contactService: ContactService;
    callService: CallService;
    callRecordingService: CallRecordingService;
    smsService: SmsService;
    mmsService: MmsService;
    voicemailService: VoicemailService;
    faxService: FaxService;
    draftService: DraftService;
  }) {
    super({
      authService,
      contactService,
      callService,
      callRecordingService,
      smsService,
      mmsService,
      voicemailService,
      faxService,
      draftService,
    });
    this.authService = authService;
    this.contactService = contactService;
    this.callService = callService;
    this.callRecordingService = callRecordingService;
    this.smsService = smsService;
    this.mmsService = mmsService;
    this.voicemailService = voicemailService;
    this.faxService = faxService;
    this.draftService = draftService;
  }

  public update() {
    this.messagesByContactId = {};
    super.update();
  }

  public async onDependentServiceUpdate() {
    this.update();
  }

  public async getMessagesByContactId(
    contactId: Id,
    force = false
  ): Promise<Message[]> {
    const initialUpdates = this.updates;
    if (force || !this.messagesByContactId[contactId]) {
      this.messagesByContactId[contactId] = new Promise(async (resolve) => {
        const messages = [
          ...this.callService.getMessagesByContactId(contactId),
          ...this.callRecordingService.getMessagesByContactId(contactId),
          ...(await this.smsService.getMessagesByContactId(contactId)),
          ...this.mmsService.getMessagesByContactId(contactId),
          ...this.voicemailService.getMessagesByContactId(contactId),
          ...this.faxService.getMessagesByContactId(contactId),
        ];
        if (this.updates !== initialUpdates) {
          resolve(await this.getMessagesByContactId(contactId, true));
          return;
        }
        messages.sort(sortByAtDescFunc);
        resolve(messages);
      });
    }
    return this.messagesByContactId[contactId];
  }

  get totalMessageCount(): number {
    return (
      this.callService.totalMessageCount +
      this.callRecordingService.totalMessageCount +
      this.smsService.totalMessageCount +
      this.mmsService.totalMessageCount +
      this.voicemailService.totalMessageCount +
      this.faxService.totalMessageCount
    );
  }

  get loadedCount(): number {
    return (
      this.callService.totalMessageCount +
      this.callRecordingService.loadedCount +
      this.smsService.totalMessageCount +
      this.mmsService.totalMessageCount +
      this.voicemailService.totalMessageCount +
      this.faxService.totalMessageCount
    );
  }

  public async loadMore(): Promise<void> {
    await Promise.all([
      // this.callService.loadMore(),
      this.callRecordingService.loadMore(),
      // this.smsService.loadMore(),
      // this.mmsService.loadMore(),
      // this.voicemailService.loadMore(),
      // this.faxService.loadMore(),
    ]);
  }

  public get hasMore(): boolean {
    return (
      // this.callService.hasMore ||
      this.callRecordingService.hasMore
      // this.smsService.hasMore ||
      // this.mmsService.hasMore ||
      // this.voicemailService.hasMore ||
      // this.faxService.hasMore ||
    );
  }

  public async sendDraftMessage(
    contactId: Id = this.contactService.current?.id || ""
  ): Promise<any> {
    const draftMessage = this.draftService.getDraftMessage(contactId);
    if (!draftMessage?.type) {
      return;
    }
    switch (draftMessage.type) {
      case MessageType.sms:
        await this.smsService.sendDraftMessage();
        break;
      case MessageType.mms:
        await this.mmsService.sendDraftMessage();
        break;
      case MessageType.fax:
        await this.faxService.sendDraftMessage();
        break;
    }
    this.draftService.deleteDraftMessage(contactId);
  }

  public async sendMessage(message: Message): Promise<void> {
    switch (message.type) {
      case MessageType.sms:
        return this.smsService.sendMessage(message);
      case MessageType.mms:
        return this.mmsService.sendMessage(message);
      case MessageType.fax:
        return this.faxService.sendMessage(message);
    }
  }

  public async deleteMessage(message: Message): Promise<void> {
    switch (message.type) {
      case MessageType.fax:
        return this.faxService.deleteMessage(message);
    }
  }

  public async getUnreadMessages({
    contactId,
    myPhoneNumber,
    messageTypes,
  }: {
    contactId?: Id;
    myPhoneNumber?: PhoneNumber;
    messageTypes?: MessageType[];
  }): Promise<Message[]> {
    const types = messageTypes?.length ? messageTypes : allMessageTypes;
    const contacts = contactId
      ? ([this.contactService.getContactById(contactId)].filter(
          Boolean
        ) as Contact[])
      : this.contactService.contacts;
    const result: Message[] = [];
    const minReadAt =
      this.authService.account.customData.readAt["minReadAt"] || 0;
    for (const contact of contacts) {
      for (const messageType of types) {
        const readAt = (this.authService.account.customData.readAt || {})[
          [contact.id, messageType].join(":")
        ];
        const service = {
          [MessageType.call]: this.callService,
          [MessageType.sms]: this.smsService,
          [MessageType.mms]: this.mmsService,
          [MessageType.fax]: this.faxService,
          [MessageType.voicemail]: this.voicemailService,
          [MessageType.callRecording]: this.callRecordingService,
        }[messageType];
        result.push(
          ...(await service.getMessagesByContactId(contact.id)).filter(
            (message) =>
              message.direction === Direction.in &&
              (!myPhoneNumber || message.myNumber === myPhoneNumber) &&
              message.at > minReadAt &&
              (!readAt || message.at > readAt)
          )
        );
      }
    }
    return result;
  }

  public async getUnreadCount({
    contactId,
    myPhoneNumber,
    messageTypes,
  }: {
    contactId?: Id;
    myPhoneNumber?: PhoneNumber;
    messageTypes?: MessageType[];
  }): Promise<number> {
    return (
      await this.getUnreadMessages({ contactId, myPhoneNumber, messageTypes })
    ).length;
  }
}
