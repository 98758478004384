import React, { useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import { BrandManagement } from "./BrandManagement/BrandManagement";
import "./Config.sass";

enum Tab {
  BrandManagement = 0,
}

const tabNames: Record<Tab, string> = {
  [Tab.BrandManagement]: "Brand Management",
};

export const Config: React.FC = () => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <div className="config-page">
      <TabView
        activeIndex={tabIndex}
        onTabChange={({ index }) => setTabIndex(index)}
      >
        {Object.values(tabNames).map((tabName, i) => (
          <TabPanel key={i} header={tabName} />
        ))}
      </TabView>

      {tabIndex === Tab.BrandManagement && <BrandManagement />}
    </div>
  );
};
