import React from "react";
import { Media } from "models/Media";
import { Button } from "primereact/button";
import { downloadFile } from "helpers/file";
import "./MediaMessageContent.scss";

interface MediaMessageContentProps {
  media: Media;
}

export const MediaMessageContent: React.FC<MediaMessageContentProps> = ({
  media,
}) => {
  if (!media?.url) {
    return null;
  }
  return (
    <div className="media-message-content-component">
      {(media.mimeType || "").startsWith("image") ? (
        <div className="image">
          <img
            className="clickable"
            src={media.url}
            alt={media.description}
            onClick={() => downloadFile(media.url || "")}
          />
        </div>
      ) : (
        <>
          <Button
            className="p-button-rounded download-btn"
            onClick={() => downloadFile(media.url || "")}
            icon="pi pi-download"
            label="Download"
          ></Button>
          {media.width && media.height ? (
            <div className="dimensions">
              {media.width} × {media.height}
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};
