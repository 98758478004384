import { RefObject } from "react";
import { Feature, MyNumber } from "models/MyNumber";
import { MyNumberService, useMyNumberService } from "./useMyNumberService";
import { WebSocketInterface, UA, debug as sipDebug } from "jssip";
import { RTCSessionEvent } from "jssip/lib/UA";
import {
  RTCSession,
  RTCSessionEventMap,
  EndEvent,
  IncomingEvent,
  OutgoingEvent,
} from "jssip/lib/RTCSession";
import { CallOptions } from "jssip/lib/UA";
import {
  CallState,
  DeviceType,
  Direction,
  Message,
  MessageType,
} from "models/Message";
import { AudioService, useAudioService } from "./useAudioService";
import { storageKeys } from "const/storage-keys";
import { ContactService, useContactService } from "./useContactService";
import { getStorageItem, setStorageItem } from "helpers/storage";
import { Id, PhoneNumber } from "models/common";
import { showToast } from "helpers/toast";
import { generateGuid } from "helpers/guid";
import * as _ from "lodash-es";
import { BrandService, useBrandService } from "./useBrandService";
import { useService, Service, UpdateDispatcher } from "./useService";
import { AuthService, useAuthService } from "./useAuthService";
import { urls } from "const/urls";
import { ApiService, useApiService } from "./useApiService";

const ud: UpdateDispatcher = new Set();

export function useCallService({
  remoteAudioRef,
  fcmToken,
}: {
  remoteAudioRef?: RefObject<HTMLAudioElement>;
  fcmToken?: string;
} = {}): CallService {
  const api = useApiService();
  const auth = useAuthService();
  const audio = useAudioService();
  const myNumberService = useMyNumberService();
  const authService = useAuthService();
  const contactService = useContactService();
  const brandService = useBrandService();
  const service = useService(ud, CallService, {
    api,
    auth,
    audio,
    myNumberService,
    authService,
    contactService,
    brandService,
  });
  if (remoteAudioRef) {
    service.setRemoteAudio(remoteAudioRef.current || undefined);
  }
  if (fcmToken) {
    service.setFcmToken(fcmToken);
  }
  return service;
}

// types using for API
enum CallDirection {
  NONE = "NONE",
  INCOMING = "INCOMING",
  OUTGOING = "OUTGOING",
}
enum CallStatus {
  NEW = "NEW",
  ANSWERED = "ANSWERED",
  NOANSWER = "NOANSWER",
  REJECTED = "REJECTED",
  CANCELED = "CANCELED",
  FAILED = "FAILED",
}
enum ApiDeviceType {
  MOBILE = "MOBILE",
  WEB = "WEB",
}
interface ICallHistoryRecord {
  call_id: string;
  self_phone_number: string;
  peer_phone_number: string;
  direction?: CallDirection;
  status?: CallStatus;
  device_type?: ApiDeviceType;
  device_fcm_id?: string;
  start_time: number;
  answer_time?: number;
  end_time?: number;
  error_code?: number;
  error_cause?: string;
  error_reason_phrase?: string;
}

export class CallService extends Service {
  public static serviceName = "CallService";

  private callHistory: Record<Id, Message[]> | undefined = getStorageItem(
    storageKeys.calls.messages
  ); // key is contactId; messages are sorted `at desc`; undefined means that it was not loaded yet
  private _currentCall: Message | undefined;
  private _currentSession: RTCSession | undefined;
  private _incomingCallRinging = false;
  private _outgoingCallRinging = false;
  private myCallsPhoneNumbers: PhoneNumber[] = [];

  // are provided to the service from outside
  private _remoteAudio: HTMLAudioElement | undefined;
  private _fcmToken: string = "";

  private readonly api: ApiService;
  private readonly auth: AuthService;
  private readonly audio: AudioService;
  private readonly myNumberService: MyNumberService;
  private readonly authService: AuthService;
  private readonly contactService: ContactService;
  private readonly brandService: BrandService;

  constructor({
    api,
    auth,
    audio,
    myNumberService,
    authService,
    contactService,
    brandService,
  }: {
    api: ApiService;
    auth: AuthService;
    audio: AudioService;
    myNumberService: MyNumberService;
    authService: AuthService;
    contactService: ContactService;
    brandService: BrandService;
  }) {
    super({
      auth,
      audio,
      myNumberService,
      authService,
      contactService,
      brandService,
    });
    sipDebug.enable("JsSIP:Transport JsSIP:RTCSession*");
    this.api = api;
    this.auth = auth;
    this.audio = audio;
    this.myNumberService = myNumberService;
    this.authService = authService;
    this.contactService = contactService;
    this.brandService = brandService;
  }

  public async init() {
    this.onDependentServiceUpdate({ serviceName: MyNumberService.name });
    this.auth.onLoginHandlers.push(() =>
      this.onDependentServiceUpdate({ serviceName: MyNumberService.name })
    );
    this.auth.onLogoutHandlers.push(() => {
      this.callHistory = undefined;
      this._currentCall = this._currentSession = this._remoteAudio = undefined;
      this._incomingCallRinging = this._outgoingCallRinging = false;
      this.myCallsPhoneNumbers = [];
    });
  }

  public update() {
    setStorageItem(storageKeys.calls.messages, this.callHistory);
    super.update();
    if (this.currentCall) {
      this.contactService.update(); // update last message of the contact by current call
    }
  }

  public async onDependentServiceUpdate({
    serviceName,
  }: {
    serviceName: string;
  }) {
    switch (serviceName) {
      case MyNumberService.name: {
        if (
          this.auth.callRecording ||
          this.auth.callForwarding ||
          this.auth.voicemail ||
          this.auth.fax
        ) {
          return;
        }
        const myCallsPhoneNumbers =
          this.authService.callRecording || this.authService.callForwarding
            ? []
            : this.myNumberService.myNumbers.filter((n) =>
                n.features.includes(Feature.calls)
              );
        const newMyNumbers = myCallsPhoneNumbers.filter(
          (n) => !this.myCallsPhoneNumbers.includes(n.number)
        );
        this.myCallsPhoneNumbers = myCallsPhoneNumbers.map((n) => n.number);
        if (!newMyNumbers.length) {
          break;
        }
        for (const myNumber of newMyNumbers) {
          this.establishSipConnection(myNumber);
        }
        break;
      }
    }
  }

  protected async loadCallHistory() {
    interface ICallHistoryResponse {
      entries: ICallHistoryRecord[];
      has_more: boolean;
    }
    const response = await this.api.get<ICallHistoryResponse>(
      urls.callHistory,
      { ignoreErrors: [404] }
    );
    console.log("call history response", response);
    if (!response) {
      return;
    }
    response.entries.forEach((entry) => {
      this.callHistory = this.callHistory || {};
      this.callHistory[entry.peer_phone_number] =
        this.callHistory[entry.peer_phone_number] || [];
      this.callHistory[entry.peer_phone_number].push({
        myNumber: entry.self_phone_number,
        contactNumber: entry.peer_phone_number,
        direction:
          entry.direction === CallDirection.INCOMING
            ? Direction.in
            : Direction.out,
        type: MessageType.call,
        entityId: entry.call_id,
        at: entry.start_time,
        deviceType:
          entry.device_type === ApiDeviceType.MOBILE
            ? DeviceType.mobile
            : entry.device_type === ApiDeviceType.WEB
            ? DeviceType.web
            : undefined,
      });
      this.callHistory[entry.peer_phone_number].sort((a, b) => b.at - a.at);
    });
  }

  private getCallHistoryRecord(call: Message): ICallHistoryRecord {
    const startTime = call.sentAt || call.at;
    return {
      call_id: call.entityId || "",
      self_phone_number: call.myNumber,
      peer_phone_number: call.contactNumber,
      direction:
        call.direction === Direction.in
          ? CallDirection.INCOMING
          : CallDirection.OUTGOING,
      status:
        call.callState === CallState.connecting
          ? CallStatus.NEW
          : call.callState === CallState.ringing
          ? CallStatus.NEW
          : call.callState === CallState.ongoing
          ? CallStatus.ANSWERED
          : call.callState === CallState.ended
          ? CallStatus.ANSWERED
          : call.callState === CallState.cancelled
          ? CallStatus.CANCELED
          : call.callState === CallState.noAnswer
          ? CallStatus.NOANSWER
          : call.callState === CallState.rejected
          ? CallStatus.REJECTED
          : call.callState === CallState.missed
          ? CallStatus.NEW
          : CallStatus.FAILED,
      device_type: ApiDeviceType.WEB,
      device_fcm_id: this._fcmToken,
      start_time: startTime && Math.round(startTime / 1000),
      answer_time: call.callStartedAt && Math.round(call.callStartedAt / 1000),
      end_time: call.at && Math.round(call.at / 1000),
      // TODO: add error fields
      // error_code
      // error_cause
      // error_reason_phrase
    };
  }

  protected async saveCallHistoryRecord(call: Message) {
    const record = this.getCallHistoryRecord(call);
    const response = await this.api.post(urls.callHistory, record);
    console.log("POST call history response", response);
  }

  public getMessagesByContactId(contactId: Id): Message[] {
    return (this.callHistory || {})[contactId] || [];
  }

  public get currentCall(): Message | undefined {
    return this._currentCall;
  }

  public get muted(): boolean {
    return this.currentSession?.isMuted()?.audio || false;
  }
  public set muted(value: boolean) {
    if (!this.currentSession) {
      return;
    }
    if (value) {
      this.currentSession.mute();
    } else {
      this.currentSession.unmute();
    }
  }

  public get onHold(): boolean {
    return this.currentSession?.isOnHold()?.local || false;
  }
  public set onHold(value: boolean) {
    if (!this.currentSession) {
      return;
    }
    if (value) {
      this.currentSession.hold();
    } else {
      this.currentSession.unhold();
    }
  }

  protected get currentSession(): RTCSession | undefined {
    return this._currentSession;
  }
  protected set currentSession(session: RTCSession | undefined) {
    this._currentSession = session;
    if (!session) {
      if (this._remoteAudio) {
        this._remoteAudio.pause();
        this._remoteAudio.srcObject = null;
      }
      return;
    }
    session.connection?.addEventListener("addstream", () => {
      this.addTracksToStream();
    });
  }

  protected get incomingCallRinging(): boolean {
    return this._incomingCallRinging;
  }
  protected set incomingCallRinging(value: boolean) {
    this._incomingCallRinging = value;
    if (value) {
      this.audio.play("ringing");
    } else {
      this.audio.stop("ringing");
    }
  }

  protected get outgoingCallRinging(): boolean {
    return this._outgoingCallRinging;
  }
  protected set outgoingCallRinging(value: boolean) {
    this._outgoingCallRinging = value;
    if (value) {
      this.audio.play("ringback");
    } else {
      this.audio.stop("ringback");
    }
  }

  private establishSipConnection = (myNumber: MyNumber) => {
    const { transport, server, port, userAgent } = this.brandService.brand.sip;

    const socket = new WebSocketInterface(`${transport}://${server}:${port}`);
    socket.via_transport = transport;

    const ua = new UA({
      sockets: [socket],
      uri: `sip:${myNumber.number}@${server}`,
      password: myNumber.calls!.sipPassword,
      authorization_user: myNumber.number,
      display_name: myNumber.number,
      user_agent: userAgent,
    });
    myNumber.calls!.sipAgent = ua;
    ua.registrator().setExtraHeaders([`Origin: https://${server}`]);
    ua.on("registered", this.onSipRegistrationChange(myNumber, true));
    ua.on("unregistered", this.onSipRegistrationChange(myNumber, false));
    ua.on("connected", () => console.log(`SIP ${myNumber.name} connected`));
    ua.on("disconnected", () =>
      console.log(`SIP ${myNumber.name} disconnected`)
    );
    ua.on("newRTCSession", this.onNewRTCSession(myNumber));
    ua.start();

    return ua;
  };

  private onSipRegistrationChange(
    myNumber: MyNumber,
    ready: boolean
  ): () => void {
    return () => {
      console.log(
        `SIP ${myNumber.name} ${ready ? "registered" : "unregistered"}`
      );
      myNumber.calls!.ready = ready;
      this.update();
      this.myNumberService.update();
    };
  }

  private onNewRTCSession(myNumber: MyNumber): (data: RTCSessionEvent) => void {
    return (data: RTCSessionEvent) => {
      const direction =
        data.originator === "remote" ? Direction.in : Direction.out;
      if (direction === Direction.out) {
        return;
      }

      // if busy or other incoming then reply with 486 "Busy Here"
      if (this.currentSession) {
        data.session.terminate({
          status_code: 486,
          reason_phrase: "Busy Here",
        });
        return;
      }

      const { session } = data;
      this.currentSession = session;
      this.incomingCallRinging = true;

      this.createCurrentCall({
        myNumber,
        contactNumber: session.remote_identity.uri.user,
        direction,
      });

      const logSessionEvents = <T extends keyof RTCSessionEventMap>(
        eventNames: T[]
      ) => {
        for (const eventName of eventNames) {
          session.on(eventName, (...args: any[]) =>
            console.log(`SIP ${myNumber.name} event ${eventName}`, ...args)
          );
        }
      };
      logSessionEvents([
        "update",
        "connecting",
        "sdp",
        "sending",
        "peerconnection:setremotedescriptionfailed",
        "peerconnection:setlocaldescriptionfailed",
        "peerconnection:createanswerfailed",
        "peerconnection:createofferfailed",
      ]);

      session
        .on("accepted", (data: IncomingEvent | OutgoingEvent) => {
          console.log(`SIP ${myNumber.name} event accepted`, data);
          this.incomingCallRinging = false;
          this.addTracksToStream();
          const call = this.currentCall!;
          call.callState = CallState.ongoing;
          call.callStartedAt = call.at = new Date().getTime();
        })
        .on("ended", (data: EndEvent) => {
          console.log(`SIP ${myNumber.name} event ended`, data);
          this.onCallEnd(data);
        })
        .on("failed", (data: EndEvent) => {
          console.log(`SIP ${myNumber.name} event failed`, data);
          this.onCallEnd(data);
        });
      console.log(session);
    };
  }

  private startIceTimer(session: RTCSession | undefined) {
    try {
      let ready: any = null;

      setTimeout(function () {
        try {
          if (ready) {
            console.log("We got Ice timeout here so calling now");
            ready();
          }
        } catch (e) {
          console.error(e);
        }
      }, 5000);

      session?.on("icecandidate", (data: any) => {
        ready = data.ready;
      });

      session?.on("sdp", () => {
        ready = null;
      });
    } catch (e) {
      console.error(e);
    }
  }

  public async dial(contactNumber: string) {
    const myNumber = this.myNumberService.current;
    if (!myNumber?.features.includes(Feature.calls)) {
      if (myNumber) {
        showToast({
          severity: "warn",
          summary: "Disabled feature",
          detail: `Sorry, unfortunately Calls are disabled for ${myNumber.name}.`,
        });
      } else {
        showToast({
          severity: "warn",
          summary: "Unknown source number",
          detail: `Please select one of your phone numbers which should be used for the call.`,
        });
      }
      return;
    }
    if (!myNumber.calls?.ready || !myNumber.calls.sipAgent) {
      showToast({
        severity: "warn",
        summary: `Source number is not registered`,
        detail: `Your phone number ${myNumber.name} is not ready yet for calling. Please try again later. If the error persist, please contact us.`,
      });
      return;
    }
    this.createCurrentCall({
      myNumber,
      contactNumber,
      direction: Direction.out,
    });

    const eventHandlers: CallOptions["eventHandlers"] = {
      progress: (event: IncomingEvent) => {
        console.log("Call progressing", event, session);
        this.outgoingCallRinging = true;
        const call = this.currentCall!;
        call.callState = CallState.ringing;
        call.sentAt = call.at = new Date().getTime();
        this.update();
      },
      confirmed: (event: IncomingEvent) => {
        console.log("Call confirmed", event, session);
        this.outgoingCallRinging = false;
        this.audio.play("answered");
        const call = this.currentCall!;
        call.callState = CallState.ongoing;
        call.callStartedAt = call.at = new Date().getTime();
        this.update();
      },
      failed: (data: EndEvent) => {
        console.log("Call failed", data, session);
        this.audio.play("rejected");
        const call = this.currentCall!;
        this.onCallEnd(data);
        showToast({
          severity: "warn",
          summary: `Call failed`,
          detail: `The call has been ${call.callState}.`,
        });
        this.update();
      },
      ended: (data: EndEvent) => {
        console.log("Call ended", data, session);
        this.onCallEnd(data);
      },
    };

    const session = myNumber.calls.sipAgent.call(contactNumber, {
      pcConfig: { iceServers: [{ urls: ["stun:stun.l.google.com:19302"] }] },
      eventHandlers,
      mediaConstraints: { audio: true, video: false },
      rtcAnswerConstraints: {
        offerToReceiveAudio: true,
        offerToReceiveVideo: false,
      },
      rtcOfferConstraints: {
        offerToReceiveAudio: true,
        offerToReceiveVideo: false,
      },
    });

    this.currentSession = session;
    this.startIceTimer(session);
  }

  public hangUp() {
    if (!this.currentSession) {
      return;
    }
    this.currentSession.terminate({
      cause: "Terminated",
      reason_phrase: "normal_disconnect",
    });
  }

  public answer() {
    if (!this.currentSession) {
      return;
    }
    this.currentSession.answer();
  }

  // don't use it directly (other that inside useCallService)
  public setRemoteAudio(remoteAudio: HTMLAudioElement | undefined) {
    this._remoteAudio = remoteAudio;
  }
  public setFcmToken(fcmToken: string) {
    this._fcmToken = fcmToken;
  }

  private createCurrentCall({
    myNumber,
    contactNumber,
    direction,
  }: {
    myNumber: MyNumber;
    contactNumber: string;
    direction: Direction;
  }) {
    const now = new Date().getTime();
    const currentCall: Message = {
      myNumber: myNumber.number,
      contactNumber,
      direction,
      type: MessageType.call,
      entityId: generateGuid(),
      at: now, // later will be overriden by startedAt, and finally by endedAt
      sentAt: now,
      callState:
        direction === Direction.out ? CallState.connecting : CallState.ringing,
      deviceType: DeviceType.web,
    };
    const contact =
      this.contactService.getContactByNumber(contactNumber) ||
      this.contactService.addNewContact(contactNumber, currentCall);
    this.contactService.current = contact;
    contact.hasCalls = true;
    contact.lastMessage = currentCall;
    this.callHistory = this.callHistory || {};
    this.callHistory[contact.id] = this.callHistory[contact.id] || [];
    this.callHistory[contact.id].unshift(currentCall);
    this._currentCall = currentCall;
    this.update();
  }

  private onCallEnd(data: EndEvent) {
    console.log("Call ended, reason:", data.cause);
    this.incomingCallRinging = false;
    this.outgoingCallRinging = false;
    const call = this.currentCall;
    if (!call) {
      return;
    }
    call.at = new Date().getTime(); // ended at
    call.callStartedAt = call.callStartedAt || call.at;
    const getNextCallState = () => {
      if (call.direction === Direction.in) {
        switch (call.callState) {
          case CallState.ringing:
            return data.cause === "Cancelled" || data.cause === "Terminated"
              ? CallState.cancelled
              : CallState.missed;
          case CallState.ongoing:
            return CallState.ended;
        }
      } else {
        switch (call.callState) {
          case CallState.connecting:
            return CallState.cancelled;
          case CallState.ringing:
            return data.cause === "Rejected"
              ? CallState.rejected
              : CallState.noAnswer;
          case CallState.ongoing:
            return CallState.ended;
        }
      }
    };
    call.callState = getNextCallState();
    this._currentCall = this.currentSession = undefined;
    this.update();
    this.saveCallHistoryRecord(call);
  }

  private addTracksToStream = () => {
    const session = this.currentSession;
    if (!session) {
      return;
    }
    const remoteTrackList = session.connection.getReceivers();

    const updatedStream = new MediaStream();
    remoteTrackList.map((rtpReceiver) =>
      updatedStream.addTrack(rtpReceiver.track)
    );
    if (this._remoteAudio) {
      this._remoteAudio.srcObject = updatedStream;
      this._remoteAudio.play();
    }
  };

  public sendDTMF(digit: string) {
    if (!this._currentSession) {
      return;
    }
    const dtmfSender = this._currentSession.connection.getSenders()[0].dtmf;
    if (!dtmfSender) {
      return;
    }
    dtmfSender.insertDTMF(digit);
  }

  public canSendDTMF(): boolean {
    if (!this._currentSession) {
      return false;
    }
    const dtmfSender = this._currentSession.connection.getSenders()[0].dtmf;
    return dtmfSender?.canInsertDTMF || false;
  }

  public getAllMessages(): Message[] {
    return _.flatten(Object.values(this.callHistory || {}));
  }

  get totalMessageCount(): number {
    return _.flatten(Object.values(this.callHistory || {})).length;
  }
}
