import React, { useCallback } from "react";
import { useDrop } from "react-dnd";
import { Card } from "primereact/card";
import { MyNumberGreeting } from "hooks/services/useGreetingService";
import { TiDelete } from "react-icons/ti";

interface MyNumberGreetingCardProps {
  myNumberGreeting: MyNumberGreeting;
  unsetGreeting: ({ myNumber }: { myNumber: string }) => void;
}

export const MyNumberGreetingCard: React.FC<MyNumberGreetingCardProps> = ({
  myNumberGreeting,
  unsetGreeting,
}) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: "greeting",
    drop: () => myNumberGreeting,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const active = canDrop && isOver;

  const removeClick = useCallback(
    (myNumber: string) => () => unsetGreeting({ myNumber }),
    [unsetGreeting]
  );

  return (
    <div ref={drop}>
      <Card
        className={`number-card ${active && "active"}`}
        title={myNumberGreeting.myNumber.name}
      >
        {myNumberGreeting.greeting ? (
          <>
            <audio controls={true} src={myNumberGreeting.greeting.url} />
            <TiDelete
              className="clickable"
              onClick={removeClick(myNumberGreeting.myNumber.number)}
            />
          </>
        ) : (
          <div className="drop-zone">Drag voicemail greeting here</div>
        )}
      </Card>
    </div>
  );
};
