import React, { useCallback, useEffect, useState } from "react";
import { Divider } from "primereact/divider";
import { Loader } from "components/Loader/Loader";
import { CallProcedureCard } from "./CallProcedureCard";
import { MyNumberCallProcedureCard } from "./MyNumberCallProcedureCard";
import { Search } from "components/Search/Search";
import { useSearch } from "hooks/useSearch";
import {
  MyNumberCallProcedure,
  useCallProceduresService,
} from "hooks/services/useCallProceduresService";
import { CallProcedure } from "models/CallProcedure";
import { Id } from "models/common";
import "./CallProcedures.sass";

export const CallProcedures: React.FC = () => {
  const callProceduresService = useCallProceduresService();

  const [numberCallProcedures, setNumberCallProcedures] = useState<
    MyNumberCallProcedure[] | undefined
  >();
  const [callProcedures, setCallProcedures] = useState<
    CallProcedure[] | undefined
  >();
  const [loading, setLoading] = useState(false);
  const [searchPattern, setSearchPattern] = useState("");

  useEffect(() => {
    if (callProceduresService.isReady && !numberCallProcedures) {
      callProceduresService.loadMyNumberCallProcedures().then((result) => {
        setCallProcedures(result.callProcedures);
        setNumberCallProcedures(result.myNumberCallProcedures);
      });
    }
  }, [
    callProceduresService.isReady,
    callProceduresService,
    numberCallProcedures,
  ]);

  // either callProcedures or oldCallProcedureId should be defined
  const setMyNumberCallProcedure = useCallback(
    async ({
      myNumber,
      callProcedureId,
      oldCallProcedureId,
    }: {
      myNumber: string;
      callProcedureId?: Id;
      oldCallProcedureId?: Id;
    }) => {
      const index = (numberCallProcedures || []).findIndex(
        (x) => x.myNumber.number === myNumber
      );
      const newNumberCallProcedures = numberCallProcedures || []; // [...(numberCallProcedures || [])];
      const callProcedure = callProcedureId
        ? (callProcedures || []).find((g) => g.id === callProcedureId)
        : undefined;
      newNumberCallProcedures.splice(index, 1, {
        myNumber: newNumberCallProcedures[index].myNumber,
        callProcedure,
      });
      setNumberCallProcedures(newNumberCallProcedures);
      setLoading(true);
      try {
        if (callProcedureId) {
          await callProceduresService.addMyNumberCallProcedure({
            myNumber,
            callProcedureId,
          });
        } else if (oldCallProcedureId) {
          await callProceduresService.removeMyNumberCallProcedure({
            myNumber,
            callProcedureId: oldCallProcedureId!,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [callProcedures, callProceduresService, numberCallProcedures]
  );

  const unsetMyNumberCallProcedure = useCallback(
    async ({
      myNumber,
      callProcedureId,
    }: {
      myNumber: string;
      callProcedureId: Id;
    }) => {
      setMyNumberCallProcedure({
        myNumber,
        oldCallProcedureId: callProcedureId,
      });
    },
    [setMyNumberCallProcedure]
  );

  const hasCallProcedures = Boolean(callProcedures?.length);

  const filteredCallProcedures = useSearch({
    items: callProcedures || [],
    searchPattern,
    fields: ["label"],
  });

  if (!callProcedures || !numberCallProcedures) {
    return <Loader />;
  }

  return (
    <div className="call-procedures-component auto-flex">
      {loading && <Loader />}
      <div className="call-procedures-panel">
        <div className="call-procedures auto-flex">
          {hasCallProcedures ? (
            <>
              <Search
                searchPattern={searchPattern}
                setSearchPattern={setSearchPattern}
              />
              <div className="call-procedures-cards auto-flex">
                {filteredCallProcedures.map((callProcedure) => (
                  <CallProcedureCard
                    key={callProcedure.id}
                    callProcedure={callProcedure}
                    setMyNumberCallProcedure={setMyNumberCallProcedure}
                  />
                ))}
              </div>
            </>
          ) : (
            <div className="no-call-procedures">No call procedures found</div>
          )}
        </div>
        {hasCallProcedures && (
          <>
            <Divider layout="vertical" />
            <div className="numbers">
              <p>
                Please drag call procedures over the phone number
                {numberCallProcedures.length > 1 && "s"}
              </p>
              {numberCallProcedures.map((numberCallProcedure) => (
                <MyNumberCallProcedureCard
                  key={numberCallProcedure.myNumber.number}
                  numberCallProcedure={numberCallProcedure}
                  unsetMyNumberCallProcedure={unsetMyNumberCallProcedure}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
