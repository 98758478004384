import React, { useEffect, useState } from "react";
import { Loader } from "components/Loader/Loader";
import { Splitter, SplitterPanel } from "primereact/splitter";
import { SmsCampaigns } from "./SmsCampaigns/SmsCampaigns";
import { SmsLists } from "./SmsLists/SmsLists";
import { useSmsListService } from "hooks/services/useSmsListService";
import { TabPanel, TabView } from "primereact/tabview";
import { SmsListRecepients } from "./SmsListRecepients/SmsListRecepients";
import "./SmsList.scss";

enum Tab {
  none = -1,
  contacts = 0,
  messages = 1,
}

export const SmsList: React.FC = () => {
  const smsListService = useSmsListService();

  const [tabIndex, setTabIndex] = useState<Tab>(Tab.none);

  const listSelected = Boolean(smsListService.currentSmsList);
  const currentListHasContacts =
    (smsListService.currentSmsList?.contactData || []).length > 0;

  useEffect(() => {
    setTabIndex(
      !listSelected
        ? Tab.none
        : currentListHasContacts
        ? tabIndex !== Tab.none
          ? tabIndex
          : Tab.messages
        : Tab.contacts
    );
  }, [listSelected, currentListHasContacts, tabIndex]);

  return (
    <div className="sms-list-component auto-flex">
      <Splitter>
        <SplitterPanel size={20} className="auto-flex">
          <SmsLists />
        </SplitterPanel>
        <SplitterPanel size={20} className="auto-flex">
          {smsListService.currentSmsList && (
            <>
              <TabView
                activeIndex={tabIndex}
                onTabChange={(e) => setTabIndex(e.index)}
              >
                <TabPanel header="Recepients">
                  {smsListService.currentSmsList && (
                    <>
                      {smsListService.smsListsLoading && <Loader />}
                      <SmsListRecepients list={smsListService.currentSmsList} />
                    </>
                  )}
                </TabPanel>
                {currentListHasContacts && (
                  <TabPanel header="Messages">
                    <SmsCampaigns />
                  </TabPanel>
                )}
              </TabView>
            </>
          )}
        </SplitterPanel>
      </Splitter>
    </div>
  );
};
