import { Token, anonymousToken, tokenExpiration } from "models/Token";
import { ApiService, useApiService } from "./useApiService";
import { environment } from "environments";
import { Account, Role, emptyAccount } from "models/Account";
import { forceReload } from "helpers/url";
import { storageKeys } from "const/storage-keys";
import { urls } from "const/urls";
import { getStorageItem, setStorageItem } from "helpers/storage";
import * as _ from "lodash-es";
import { mergeDuplicatedNumbers } from "models/Contact";
import { formatPhoneNumber } from "helpers/phone";
import { useService, Service, UpdateDispatcher } from "./useService";
import { routes } from "const/routes";

const ud: UpdateDispatcher = new Set();

export function useAuthService(): AuthService {
  const api = useApiService();
  return useService(ud, AuthService, { api });
}

interface AuthResponse {
  access: string;
  refresh: string;
}

interface InitResponse extends AuthResponse {
  customer_number: number;
}

export class AuthService extends Service {
  public static serviceName = "AuthService";

  public account: Account = structuredClone(emptyAccount);
  private cachedCustomData: any = undefined;

  private _isAutoLoggedIn = false;
  public get isAutoLoggedIn(): boolean {
    return this._isAutoLoggedIn;
  }

  public readonly onLoginHandlers: Array<() => void> = [];
  public readonly onLogoutHandlers: Array<() => void> = [];

  protected refreshThread: NodeJS.Timeout | null = null;

  private readonly api: ApiService;

  constructor({ api }: { api: ApiService }) {
    super({ api });
    this.api = api;
    this.refreshTokens = this.refreshTokens.bind(this);
  }

  public async init(): Promise<void> {
    const wasAutoLoggedIn = Boolean(this.customerNumber);
    const autoToken = await this.autoLogin();
    if (Number(autoToken) + Number(wasAutoLoggedIn) === 1) {
      // autoToken XOR wasAutoLoggedIn
      localStorage.clear();
      sessionStorage.clear();
    }
    if (!autoToken && this.token.refreshToken) {
      this.refreshTokens();
    }
    if (this.token.accessToken) {
      await this.afterLogin();
    }
  }

  get customerNumber(): string {
    const customerNumber = getStorageItem(storageKeys.auth.customerNumber) || 0;
    return customerNumber ? customerNumber.toString() : "";
  }

  get authenticated(): boolean {
    return Boolean(this.token?.accessToken);
  }

  public get token(): Token {
    return getStorageItem(storageKeys.auth.token) || anonymousToken;
  }

  private setToken(token: Token) {
    setStorageItem(storageKeys.auth.token, token);
    if (this.refreshThread) {
      clearTimeout(this.refreshThread);
    }
    this.refreshThread = setTimeout(
      this.refreshTokens,
      (tokenExpiration - 0.5) * 60000
    );
    this.update();
  }

  // noinspection JSMethodCanBeStatic
  private setCustomerNumber(customerNumber: number) {
    setStorageItem(storageKeys.auth.customerNumber, customerNumber || "");
    this.update();
  }

  async autoLogin(): Promise<Token | undefined> {
    try {
      console.log("Trying to auto-login inside the Dashboard...");
      const response = await this.api.post<InitResponse>(
        environment.api.autoLoginUrl,
        "",
        {
          allowAnonymous: true,
          ignoreErrors: [403, 0],
        }
      );
      if (!response) {
        return undefined;
      }
      const token = {
        accessToken: response.access,
        refreshToken: response.refresh,
      };
      this.setToken(token);
      this._isAutoLoggedIn = true;
      return token;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  async login(username: string, password: string): Promise<void> {
    await this.ready;
    this.isReady = false;
    this.ready = new Promise((resolve) =>
      (async () => {
        const response = await this.api.post<AuthResponse>(
          `${environment.api.authUrl}/jwt/token`,
          { username, password },
          { allowAnonymous: true }
        );
        const token = {
          accessToken: response.access,
          refreshToken: response.refresh,
        };
        this.setToken(token);
        await this.afterLogin();
        this.isReady = true;
        this.update();
        console.log(`${(this.constructor as any).serviceName} ready.`);
        resolve();
      })()
    );
  }

  async afterLogin(): Promise<void> {
    interface AccountDTO {
      current_customer: number;
      email: string;
      first_name: string;
      last_name: string;
      current_customer_balance: number;
      user_type: string;
    }
    const dto = await this.api.get<AccountDTO>(urls.accountInfo);

    const account: Account = {
      customerNumber: dto.current_customer,
      email: dto.email,
      firstName: dto.first_name,
      lastName: dto.last_name,
      balance: dto.current_customer_balance,
      customData: structuredClone(emptyAccount.customData),
      role: (dto.user_type || Role.user) as Role,
    };
    const { customerNumber } = account;
    const oldCustomerNumber = this.customerNumber;
    if (customerNumber !== Number(oldCustomerNumber)) {
      if (oldCustomerNumber) {
        this.clearStorage();
      }
      this.setCustomerNumber(customerNumber);
    }
    try {
      const response = await this.api.get<{ data: any }>(
        urls.customData.replace(
          ":customerNumber",
          account.customerNumber.toString()
        )
      );
      const {
        templates = [],
        contacts = [],
        failedMessages = [],
        readAt = {},
        branding = {},
      }: Account["customData"] = JSON.parse(response.data) || {};
      readAt["minReadAt"] = readAt["minReadAt"] || 1716500000000;
      account.customData = {
        templates,
        contacts,
        failedMessages,
        readAt,
        branding,
      };

      // fixing custom data (legacy bugs)
      let customDataUpdated = false;
      for (const contact of account.customData.contacts) {
        const isCustomName =
          (contact.name || "").trim() &&
          (contact.name || "").trim() !==
            formatPhoneNumber(contact.numbers.join(", ")).trim();
        if (contact.name && !isCustomName) {
          contact.name = "";
          customDataUpdated = true;
        }
      }
      const originalContactCount = contacts.length;
      mergeDuplicatedNumbers(account.customData.contacts);
      customDataUpdated =
        customDataUpdated ||
        originalContactCount !== account.customData.contacts.length;
      if (account.customData.failedMessages.some((m) => m.sending)) {
        account.customData.failedMessages
          .filter((m) => m.sending)
          .forEach((m) => {
            m.failed = "Message sending terminated.";
            delete m.sending;
          });
        customDataUpdated = true;
      }
      if (customDataUpdated) {
        delete this.cachedCustomData;
        setTimeout(() => this.saveCustomData());
      } else {
        this.cachedCustomData = structuredClone(account.customData);
      }
    } catch (e) {
      // no custom data yet
    }
    console.log("[Custom Data]", account.customData);
    this.account = account;

    // allow login process till the end, after than run all handlers
    setTimeout(() => {
      this.onLoginHandlers.forEach((onLogin) => {
        try {
          onLogin();
        } catch (e) {
          console.error(e);
        }
      });
    });
  }

  async logout() {
    try {
      if (this.refreshThread) {
        clearTimeout(this.refreshThread);
        this.refreshThread = null;
      }
      this.clearStorage();
    } catch (e) {
      console.error(e);
      // exit silently
    }
    this.account = structuredClone(emptyAccount);
    this.cachedCustomData = undefined;
    this._isAutoLoggedIn = false;
    this.onLogoutHandlers.forEach((h) => {
      try {
        h();
      } catch (e) {
        console.error(e);
      }
    });
    global.location.href = routes.home;
  }

  async saveCustomData() {
    if (
      !this.account.customerNumber ||
      _.isEqual(this.account.customData, this.cachedCustomData)
    ) {
      return;
    }
    await this.api.post(
      urls.customData.replace(
        ":customerNumber",
        this.account.customerNumber.toString()
      ),
      { data: JSON.stringify(this.account.customData) }
    );
    this.cachedCustomData = structuredClone(this.account.customData);
    this.update();
  }

  protected clearStorage() {
    localStorage.removeItem(storageKeys.auth.token);
    localStorage.removeItem(storageKeys.auth.customerNumber);
    do {
      const keyIndex = _.range(0, localStorage.length).find((i) =>
        localStorage.key(i)?.startsWith(environment.storagePrefix)
      );
      if (keyIndex === undefined) {
        break;
      }
      localStorage.removeItem(localStorage.key(keyIndex) || "");
    } while (true);
    do {
      const keyIndex = _.range(0, sessionStorage.length).find((i) =>
        sessionStorage.key(i)?.startsWith(environment.storagePrefix)
      );
      if (keyIndex === undefined) {
        break;
      }
      sessionStorage.removeItem(sessionStorage.key(keyIndex) || "");
    } while (true);
    this.update();
  }

  protected async refreshTokens(): Promise<Token | null> {
    if (this.refreshThread) {
      clearTimeout(this.refreshThread);
      this.refreshThread = null;
    }
    const currentRefreshToken = this.token?.refreshToken;
    if (!currentRefreshToken) {
      return anonymousToken;
    }
    let token: Token;
    try {
      const response = await this.api.post<AuthResponse>(
        `${environment.api.authUrl}/jwt/refresh`,
        { refresh: currentRefreshToken }
      );
      token = {
        accessToken: response.access,
        refreshToken: response.refresh,
      };
    } catch (e: any) {
      if (typeof e === "object" && e.message?.startsWith("401")) {
        await this.logout();
        forceReload();
        return null;
      }
      throw e;
    }
    setStorageItem(storageKeys.auth.token, token);
    this.refreshThread = setTimeout(
      this.refreshTokens,
      (tokenExpiration - 0.5) * 60000
    );
    return token;
  }

  public get callRecording(): boolean {
    return this.account.role === Role.callRecording;
  }

  public get callForwarding(): boolean {
    return this.account.role === Role.callForwarding;
  }

  public get voicemail(): boolean {
    return this.account.role === Role.voicemail;
  }

  public get fax(): boolean {
    return this.account.role === Role.fax;
  }
}
