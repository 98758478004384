import { useCallback, useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useCallService } from "hooks/services/useCallService";
import { useMyNumberService } from "hooks/services/useMyNumberService";
import { Feature } from "models/MyNumber";
import { formatPhoneNumber, toDigitalNumber } from "helpers/phone";
import "./Dialer.scss";
import { getStorageItem, setStorageItem } from "helpers/storage";
import { storageKeys } from "const/storage-keys";
import { Link } from "react-router-dom";
import { routes } from "const/routes";
import { BsTelephone } from "react-icons/bs";
import { usePlayDtmf } from "hooks/usePlayDtmf";
import { useFocus } from "hooks/useFocus";

export const Dialer = () => {
  const callService = useCallService();
  const myNumberService = useMyNumberService();
  const playDtmf = usePlayDtmf();

  // digital only
  const [contactNumber, setContactNumber] = useState<string>(
    getStorageItem(storageKeys.calls.dialingNumber, sessionStorage) || ""
  );

  const call = useCallback(() => {
    callService.dial(contactNumber.replace(/^\+1/, ""));
  }, [callService, contactNumber]);

  const hangUp = useCallback(() => callService.hangUp(), [callService]);

  const [focus, inpufRef] = useFocus<HTMLInputElement>();
  useEffect(() => {
    focus();
  }, [focus]);

  const canCall = Boolean(
    !callService.currentCall &&
      contactNumber &&
      myNumberService.current?.features.includes(Feature.calls)
  );

  const type = (character: string) => () => {
    playDtmf(character);
    if (callService.currentCall) {
      callService.sendDTMF(character);
    } else {
      setContactNumber(`${contactNumber}${character}`);
      focus();
    }
  };

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const number = toDigitalNumber(e.target.value);
      const newDigit =
        number.substring(0, number.length - 1) ===
        contactNumber.substring(0, number.length - 1)
          ? number[number.length - 1]
          : "";
      if (newDigit) {
        playDtmf(newDigit);
      }
      setContactNumber(number);
      setStorageItem(storageKeys.calls.dialingNumber, number, sessionStorage);
    },
    [contactNumber, setContactNumber, playDtmf]
  );

  const canDial = !callService.currentCall || callService.canSendDTMF;

  return (
    <div className="dialer-component">
      <div className="content">
        {myNumberService.current && (
          <div className="src-number">
            Call from{" "}
            <Link
              to={routes.home}
              className={!myNumberService.current.calls?.ready ? "red" : ""}
            >
              {myNumberService.current.name}
            </Link>
          </div>
        )}
        {!callService.currentCall && (
          <div className="phone-number">
            <InputText
              ref={inpufRef}
              value={formatPhoneNumber(contactNumber)}
              onChange={onInputChange}
              onKeyDown={(e) => e.key === "Enter" && canCall && call()}
            />
          </div>
        )}
        {canDial && (
          <div className="keypad">
            <Button
              className="p-button-rounded p-button-outlined"
              onClick={type("1")}
            >
              1
            </Button>
            <Button
              className="p-button-rounded p-button-outlined"
              onClick={type("2")}
            >
              2<div className="letters">ABC</div>
            </Button>
            <Button
              className="p-button-rounded p-button-outlined"
              onClick={type("3")}
            >
              3<div className="letters">DEF</div>
            </Button>
            <Button
              className="p-button-rounded p-button-outlined"
              onClick={type("4")}
            >
              4<div className="letters">GHI</div>
            </Button>
            <Button
              className="p-button-rounded p-button-outlined"
              onClick={type("5")}
            >
              5<div className="letters">JKL</div>
            </Button>
            <Button
              className="p-button-rounded p-button-outlined"
              onClick={type("6")}
            >
              6<div className="letters">MNO</div>
            </Button>
            <Button
              className="p-button-rounded p-button-outlined"
              onClick={type("7")}
            >
              7<div className="letters">PQRS</div>
            </Button>
            <Button
              className="p-button-rounded p-button-outlined"
              onClick={type("8")}
            >
              8<div className="letters">TUV</div>
            </Button>
            <Button
              className="p-button-rounded p-button-outlined"
              onClick={type("9")}
            >
              9<div className="letters">WXYZ</div>
            </Button>
            <Button
              className="p-button-rounded p-button-outlined"
              onClick={type("*")}
            >
              *
            </Button>
            <Button
              className="p-button-rounded p-button-outlined"
              onClick={type("0")}
            >
              0
            </Button>
            <Button
              className="p-button-rounded p-button-outlined"
              onClick={type("#")}
            >
              #
            </Button>
          </div>
        )}
        {!callService.currentCall && (
          <Button
            className="p-button-rounded btn-call p-button-success"
            onClick={call}
            disabled={!canCall}
          >
            <BsTelephone />
          </Button>
        )}
        {callService.currentCall && (
          <Button
            className="p-button-rounded btn-call p-button-danger"
            onClick={hangUp}
          >
            <BsTelephone />
          </Button>
        )}
      </div>
    </div>
  );
};
