import { DateTime, IdNumeric } from "./common";
import { Contact } from "./Contact";
import { Message } from "./Message";
import { Template } from "./Template";

export enum Role {
  admin = "admin",
  user = "user",
  callRecording = "call_recording",
  callForwarding = "call_forwarding",
  voicemail = "voicemail",
  fax = "fax",
}

export interface Branding {
  title?: string;
  logoDocumentId?: string;
  color?: string;
  smsTemplate?: string;
  rcsTemplate?: string;
}

export const brandContentPlaceholder = "[CONTENT]";

// key is { customerNumber, email }
export interface Account {
  customerNumber: IdNumeric;
  email: string;
  firstName: string;
  lastName: string;
  balance: number;
  customData: {
    templates: Template[];
    contacts: Contact[];
    failedMessages: Message[];
    readAt: Record<string, DateTime>; // key is [contactId, messageType].join(':')
    branding: Branding;
  };
  role: Role;
}

export const emptyAccount: Account = {
  customerNumber: 0,
  email: "",
  firstName: "",
  lastName: "",
  balance: 0,
  customData: {
    templates: [],
    contacts: [],
    failedMessages: [],
    readAt: {},
    branding: {},
  },
  role: Role.user,
};
