import React, { useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Loader } from "components/Loader/Loader";
import { useChatService } from "hooks/services/useChatService";
import { allMessageTypes, MessageType } from "models/Message";
import * as _ from "lodash-es";
import "./Unread.sass";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { useAuthService } from "hooks/services/useAuthService";
import { DateTime } from "models/common";
import { AppMode, messageTypeNavigation, Page } from "models/navigation";
import { useNavigationService } from "hooks/services/useNavigationService";

interface UnreadProps {
  myPhoneNumber?: string;
}

export const Unread: React.FC<UnreadProps> = ({ myPhoneNumber }) => {
  const navigation = useNavigationService();
  const chatService = useChatService();
  const authService = useAuthService();

  const [loading, setLoading] = React.useState(true);

  const [unreadCountByMessageType, setUnreadCountByMessageType] =
    React.useState<
      Record<MessageType, { count: number; from: DateTime | undefined }>
    >({
      [MessageType.call]: { count: 0, from: undefined },
      [MessageType.sms]: { count: 0, from: undefined },
      [MessageType.mms]: { count: 0, from: undefined },
      [MessageType.fax]: { count: 0, from: undefined },
      [MessageType.voicemail]: { count: 0, from: undefined },
      [MessageType.callRecording]: { count: 0, from: undefined },
    });

  const [minReadAtDate, setMinReadAtDate] = React.useState<Date | undefined>(
    undefined
  );
  const minReadAt: DateTime | undefined = minReadAtDate
    ? new Date(minReadAtDate).getTime()
    : undefined;

  useEffect(() => {
    (async () => {
      setLoading(true);
      setUnreadCountByMessageType(
        _.fromPairs(
          (await Promise.all(
            allMessageTypes.map((type) =>
              chatService
                .getUnreadMessages({ myPhoneNumber, messageTypes: [type] })
                .then((unreadMessages) => [
                  type,
                  {
                    count: unreadMessages.length,
                    from: unreadMessages.length
                      ? Math.min(...unreadMessages.map((m) => m.at))
                      : undefined,
                  },
                ])
            )
          )) as [MessageType, { count: number; from: DateTime | undefined }][]
        ) as Record<MessageType, { count: number; from: DateTime | undefined }>
      );
      setLoading(false);
    })();
  }, [chatService, myPhoneNumber]);

  const rows = [
    ...allMessageTypes
      .map((type) => ({
        title: type,
        count: unreadCountByMessageType[type].count,
        from: unreadCountByMessageType[type].from,
      }))
      .filter((row) => row.count > 0),
    {
      title: "Total",
      count: _.sum(Object.values(unreadCountByMessageType).map((v) => v.count)),
      from: _.min(Object.values(unreadCountByMessageType).map((v) => v.from)),
    },
  ];
  const tableVisible = rows.some((row) => row.count > 0);

  const changeMinReadAt = async () => {
    if (!minReadAt) {
      return;
    }
    setLoading(true);
    _.each(authService.account.customData.readAt, (readAt, key) => {
      if (readAt > minReadAt) {
        authService.account.customData.readAt[key] = minReadAt;
      }
    });
    await authService.saveCustomData();
    setMinReadAtDate(undefined);
    setLoading(false);
  };

  const onRowClick = async (e: any) => {
    const type = e.data.title as MessageType;
    if ((type as string) === "Total") {
      navigation.mode = AppMode.chats;
      navigation.page = Page.chats;
      return;
    }
    navigation.mode = messageTypeNavigation[type].mode;
    navigation.page = messageTypeNavigation[type].page;
  };

  return (
    <div className="activity-page-unread-tab">
      {loading ? (
        <Loader />
      ) : (
        <>
          {!tableVisible ? (
            <em>No unread messages.</em>
          ) : (
            <>
              <DataTable value={rows} onRowClick={onRowClick}>
                <Column header="Message Type" field="title"></Column>
                <Column header="Unread Message Count" field="count"></Column>
                <Column
                  header="From Date"
                  field="from"
                  body={(row) => new Date(row.from).toLocaleDateString()}
                ></Column>
              </DataTable>
            </>
          )}
          <div>
            {"Mark all messages as unread starting from "}
            <Calendar
              value={minReadAtDate}
              onChange={(e) => setMinReadAtDate(e.value || undefined)}
            />
            {minReadAt && <Button onClick={changeMinReadAt}>Unread</Button>}
          </div>
        </>
      )}
    </div>
  );
};
