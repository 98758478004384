import React from "react";
import { ProgressBar } from "primereact/progressbar";
import "./Loader.sass";

export const Loader: React.FC = () => (
  <div className="loader-component">
    <i>Loading...</i>
    <ProgressBar mode="indeterminate"></ProgressBar>
  </div>
);
