import React, {
  PropsWithChildren,
  ChangeEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useContactService } from "hooks/services/useContactService";
import { LastMessageDescription } from "./LastMessage";
import { getDateOrTimeText } from "helpers/date";
import {
  Contact,
  contactHasCustomName,
  getContactDefaultName,
  getContactDisplayName,
  getContactDisplayNameWithNumbers,
} from "models/Contact";
import { FaUserEdit } from "react-icons/fa";
import { Message } from "models/Message";
import { InputText } from "primereact/inputtext";
import { usePrevious } from "hooks/usePrevious";
import { AiFillPushpin, AiOutlinePushpin } from "react-icons/ai";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FloatLabel } from "primereact/floatlabel";
import "./ContactCard.scss";
import { Media, MediaType } from "models/Media";
import { useMediaService } from "hooks/services/useMediaService";
import { useApiService } from "hooks/services/useApiService";
import { environment } from "environments";
import { urlFromApiUrl } from "helpers/file";
import { Loader } from "components/Loader/Loader";
import defaultContactPhoto from "assets/images/default-contact-photo.svg";

const FloatLabelFixed = FloatLabel as typeof FloatLabel &
  React.FC<PropsWithChildren<{}>>;

export const ContactCard = ({
  contact,
  lastMessage,
  time,
  unreadCount,
  selectable = true,
}: {
  contact: Contact;
  lastMessage?: Message;
  time?: string;
  unreadCount?: number;
  selectable?: boolean;
}) => {
  const api = useApiService();
  const contactService = useContactService();
  const mediaService = useMediaService();

  const [editMode, setEditMode] = useState(false);

  const [contactName, setContactName] = useState(
    contactHasCustomName(contact) ? contact.name : ""
  );

  const contactNumbers = getContactDefaultName(contact);

  const selectContact = useCallback(
    (contact: Contact) => (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      contactService.current = contact;
      contactService.update();
    },
    [contactService]
  );

  const prevContactId = usePrevious(contact.id);
  useEffect(() => {
    if (contact.id === prevContactId) {
      return;
    }
    setContactName(getContactDisplayName(contact));
  }, [contact, prevContactId]);

  const submit = () => {
    contact.name = (contactName || "").trim();
    if (photoMedia?.id) {
      contact.photoDocumentId = photoMedia.id;
    } else if (photoUrl) {
      contact.photo = photoUrl;
    } else if (!photoUrl) {
      contact.photo = "";
      contact.photoDocumentId = "";
    }
    contactService.update();
    closeEditDialog();
  };

  const deleteContact = () => {
    closeEditDialog();
    contactService.deleteContact(contact);
  };

  const togglePin = () => {
    contact.pinned = !contact.pinned;
    contactService.update();
  };

  const selected = selectable && contactService.current?.id === contact.id;

  const edit = () => {
    setContactName(contactHasCustomName(contact) ? contact.name : "");
    setEditMode(true);
  };

  const closeEditDialog = () => {
    setEditMode(false);
  };

  // Contact Photo

  const initialsArray = getContactDisplayName(contact)
    .replace(/[0-9()-]/g, "")
    .trim()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase());
  const initials =
    initialsArray.length > 1
      ? initialsArray[0] + initialsArray[initialsArray.length - 1]
      : initialsArray[0] || "";

  const [uploading, setUploading] = useState(false);
  const [photoFile, setPhotoFile] = useState<File | undefined>();
  const [photoMedia, setPhotoMedia] = useState<Media | null>(null);
  const [photoUrl, setPhotoUrl] = useState<string>("");

  useEffect(() => {
    if (contact.photoDocumentId) {
      setPhotoMedia({
        type: MediaType.ContactPhoto,
        id: contact.photoDocumentId,
        url: "",
      });
    } else if (contact.photo) {
      setPhotoUrl(contact.photo);
    }
  }, [contact]);

  useEffect(() => {
    if (photoMedia?.id && !photoMedia.url) {
      (async () => {
        await mediaService.ready;
        await mediaService.loadMedia();
        const media = mediaService.getMediaById(photoMedia.id);
        if (media) {
          setPhotoMedia(media);
        } else {
          setPhotoMedia(null);
        }
      })();
    }
  }, [photoMedia, mediaService]);

  useEffect(() => {
    if (photoMedia?.url?.startsWith(environment.api.baseUrl)) {
      urlFromApiUrl(api, photoMedia.url).then((url) => setPhotoUrl(url));
    } else if (photoMedia?.url) {
      setPhotoUrl(photoMedia?.url || "");
    }
  }, [api, photoMedia?.url]);

  const onPhotoFileUploadChange = async (
    event: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setPhotoMedia(null);
    const file = event.target.files?.[0];
    setPhotoFile(file);
    if (!file) {
      return;
    }
    setUploading(true);
    try {
      const media = await mediaService.uploadMedia({
        file,
        type: MediaType.ContactPhoto,
      });
      setPhotoFile(undefined);
      await mediaService.loadMedia();
      setPhotoMedia(mediaService.getMediaById(media?.id || "") || null);
    } finally {
      setTimeout(() => setUploading(false), 100);
    }
  };

  const clearPhoto = () => {
    setPhotoMedia(null);
    setPhotoUrl("");
  };

  return (
    <div
      className={`
        contact-card-component
        ${selected && "selected"}
      `}
      onClick={selectable ? selectContact(contact) : undefined}
    >
      <div className="contact-photo">
        {photoUrl || !initials ? (
          <img
            src={photoUrl || defaultContactPhoto}
            alt={getContactDisplayName(contact)}
          />
        ) : (
          <div className="initials">{initials}</div>
        )}
      </div>
      <div className="name">
        <span title={getContactDisplayNameWithNumbers(contact)}>
          {getContactDisplayName(contact)}
        </span>
      </div>
      <div className="last-message">
        {lastMessage ? (
          <LastMessageDescription lastMessaage={lastMessage} />
        ) : null}
      </div>
      <div className="date-time">
        {time !== undefined
          ? time
          : lastMessage
          ? getDateOrTimeText(lastMessage.at)
          : null}
      </div>
      <div className="manage-contact">
        {(selected || contact.pinned) && (
          <div
            className={`contact-pin ${contact.pinned && "pinned"}`}
            onClick={() => togglePin()}
          >
            {contact.pinned ? <AiFillPushpin /> : <AiOutlinePushpin />}
          </div>
        )}
        {selected && <FaUserEdit className="edit-user" onClick={edit} />}
        {!selected && unreadCount ? (
          <div className="unread-count">{unreadCount}</div>
        ) : null}
      </div>
      <Dialog
        header="Edit Contact Details"
        footer={
          <>
            <Button className="btn-standard-width" onClick={submit}>
              OK
            </Button>
            <Button
              className="btn-standard-width"
              severity="secondary"
              onClick={closeEditDialog}
            >
              Cancel
            </Button>
          </>
        }
        visible={editMode}
        style={{ minWidth: "300px" }}
        onHide={closeEditDialog}
      >
        <div className="App-Web-Phone_edit-contact-modal">
          <div className="form-input-group">
            <FloatLabelFixed>
              <label htmlFor="contact-name-input">Contact Name</label>
              <InputText
                id="contact-name-input"
                value={contactName}
                onChange={(e) => setContactName(e.target.value)}
              />
            </FloatLabelFixed>
          </div>
          <div className="form-input-group">
            <FloatLabelFixed>
              <label>Phone Number{contact.numbers.length > 1 ? "s" : ""}</label>
              <InputText value={contactNumbers} readOnly />
            </FloatLabelFixed>
          </div>
          <div className="form-image-upload-group">
            {uploading && <Button>Uploading...</Button>}
            <div className="upload">
              {!uploading && (
                <Button>Choose Photo File{photoFile && " ✅"}</Button>
              )}
              <input
                type="file"
                name="file"
                accept="image/png,image/jpeg"
                onChange={onPhotoFileUploadChange}
              />
            </div>
            {photoMedia?.id && !photoUrl ? (
              <Loader />
            ) : (
              photoUrl && (
                <div className="contact-photo-preview">
                  <img
                    src={photoUrl}
                    alt={contactName || getContactDisplayName(contact)}
                  />
                </div>
              )
            )}
          </div>

          {photoUrl ? (
            <div className="clear-photo">
              <Button onClick={clearPhoto}>Clear Photo</Button>
            </div>
          ) : null}
          {!lastMessage && (
            <div className="form-input-group">
              <Button severity="danger" onClick={deleteContact}>
                Delete Contact
              </Button>
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
};
