import React, { useCallback, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Loader } from "components/Loader/Loader";
import { Button } from "primereact/button";
import { GoPlus } from "react-icons/go";
import { useSmsListService } from "hooks/services/useSmsListService";
import "./SmsLists.scss";
import { Dialog } from "primereact/dialog";
import { Search } from "components/Search/Search";
import { SmsList } from "models/SmsList";
import { useSearch } from "hooks/useSearch";

export const SmsLists: React.FC = () => {
  const smsListService = useSmsListService();

  const [searchPattern, setSearchPattern] = useState("");

  const selectSmsList = useCallback(
    (smsList: SmsList | undefined) => (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      smsListService.currentSmsList = smsList;
    },
    [smsListService]
  );

  const filteredSmsLists = useSearch({
    items: smsListService.smsLists,
    searchPattern,
    fields: ["name"],
    numbersSearch: (c) => c.contactData.map((cd) => cd.number),
  });

  const [newSmsListVisible, setNewSmsListVisible] = useState(false);
  const [newSmsListName, setNewSmsListName] = useState("");

  const newSmsListClick = useCallback(() => {
    setNewSmsListVisible(true);
  }, []);

  const onNewSmsListNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewSmsListName(e.target.value);
  };

  const hideNewSmsList = () => {
    setNewSmsListVisible(false);
    setNewSmsListName("");
  };

  const addNewSmsList = async () => {
    if (!(await smsListService.addNewSmsList(newSmsListName))) {
      return;
    }
    setNewSmsListName("");
    setNewSmsListVisible(false);
  };

  return (
    <div className="sms-lists-component">
      <Search
        searchPattern={searchPattern}
        setSearchPattern={setSearchPattern}
      />
      {!smsListService.isReady && <Loader />}
      <ul className="contacts" onClick={selectSmsList(undefined)}>
        {filteredSmsLists.map((smsList) => {
          return (
            <li
              className={`contact ${
                smsListService.currentSmsList?.id === smsList.id && "selected"
              }`}
              key={smsList.id}
              onClick={selectSmsList(smsList)}
            >
              <div className="name">{smsList.name}</div>
              <div className="description">
                {smsList.contactData.length === 0 ? (
                  <em>No recepients</em>
                ) : (
                  `${smsList.contactData.length} recepient${
                    smsList.contactData.length > 1 ? "s" : ""
                  }`
                )}
              </div>
            </li>
          );
        })}
        {filteredSmsLists.length === 0 ? (
          <div className="no-contact-lists">No contact lists found</div>
        ) : null}
      </ul>
      <Button
        className="p-button-rounded new-contact-btn"
        onClick={newSmsListClick}
        title="Add a new list of SMS campaign recepients"
      >
        <GoPlus />
      </Button>
      <Dialog
        header="New SMS List"
        visible={newSmsListVisible}
        onHide={hideNewSmsList}
      >
        <div>
          <InputText
            value={newSmsListName}
            onChange={onNewSmsListNameChange}
            onKeyDown={(e) =>
              (e.key === "Enter" && addNewSmsList()) ||
              (e.key === "Escape" && hideNewSmsList())
            }
            placeholder="SMS list name"
            title="SMS list name"
          />
          <button className="p-button" onClick={addNewSmsList}>
            OK
          </button>
        </div>
      </Dialog>
    </div>
  );
};
