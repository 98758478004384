import React, { useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import { Info } from "./Info/Info";
import "./Tools.sass";

enum Tab {
  Info = 0,
}

const tabNames: Record<Tab, string> = {
  [Tab.Info]: "Check Phone Number Info",
};

export const Tools: React.FC = () => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <div className="tools-page">
      <TabView
        activeIndex={tabIndex}
        onTabChange={({ index }) => setTabIndex(index)}
      >
        {Object.values(tabNames).map((tabName, i) => (
          <TabPanel key={i} header={tabName} />
        ))}
      </TabView>

      {tabIndex === Tab.Info && <Info />}
    </div>
  );
};
