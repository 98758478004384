import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useMyNumberService } from "hooks/services/useMyNumberService";
import { Feature, features, MyNumber } from "models/MyNumber";
import { Loader } from "components/Loader/Loader";
import { showToast } from "helpers/toast";
import { useChatService } from "hooks/services/useChatService";
import { useNavigationService } from "hooks/services/useNavigationService";
import { delay } from "helpers/delay";
import { Dropdown } from "primereact/dropdown";
import { featureNavigation } from "models/navigation";
import "./Home.sass";

const featureClassPrefix = "feature-";

export const Home: React.FC = () => {
  const navigation = useNavigationService();
  const myNumberService = useMyNumberService();
  const chatService = useChatService(); // includes callService, smsSerivce, voicemailService, mediaService, myNumberService, contactService

  const onRowClick = async (e: any) => {
    const classList: string[] = Array.from(
      (e.originalEvent.target as HTMLElement).classList
    );
    const feature = classList
      .find((c) => c.startsWith(featureClassPrefix))
      ?.substring(featureClassPrefix.length) as Feature;
    const myNumber = e.data as MyNumber;
    myNumberService.setCurrent(myNumber.number);
    if (!feature) {
      return;
    }
    if (!myNumber.features.includes(feature)) {
      showToast({
        severity: "warn",
        summary: "Disabled feature",
        detail: `Sorry, unfortunately ${featureNavigation[feature].name} feature is disabled for ${myNumber.name}.`,
      });
      return;
    }
    await delay(500);
    navigation.mode = featureNavigation[feature].mode;
    navigation.page = featureNavigation[feature].page;
  };

  const myNumbersOptions = myNumberService.isReady
    ? myNumberService.myNumbers.map((n) => ({ label: n.name, value: n.number }))
    : [];

  return (
    <div className="home-page">
      <div className="description">
        SMS and Voice communications User Interface for the browser environment
      </div>
      <div className="number-features">
        {myNumberService.myNumbers.length > 0 ? (
          <>
            {!chatService.isReady && <Loader />}
            <DataTable
              value={myNumberService.myNumbers}
              onRowClick={onRowClick}
            >
              <Column
                field="number"
                header="Phone Number"
                body={(myNumber) => (
                  <div
                    className={`number ${
                      myNumber.number === myNumberService.current?.number
                        ? "active"
                        : ""
                    }`}
                  >
                    {myNumber.name}
                  </div>
                )}
              ></Column>
              {features
                .filter((f) => myNumberService.hasFeature(f))
                .map((feature) => {
                  const featureClass = `${featureClassPrefix}${feature}`;
                  return (
                    <Column
                      key={feature}
                      field={feature}
                      header={featureNavigation[feature].name}
                      className={featureClass}
                      body={(myNumber: MyNumber) => {
                        const ready =
                          feature === Feature.calls
                            ? myNumber.calls?.ready
                            : feature === Feature.sms
                            ? myNumber.sms?.ready
                            : feature === Feature.mms
                            ? myNumber.mms?.ready
                            : feature === Feature.fax
                            ? myNumber.fax?.ready
                            : true;
                        return !myNumber.features.includes(feature) ? null : (
                          <div
                            className={`status ${
                              ready ? "connected" : "disconnected"
                            } ${featureClass}`}
                          >
                            {ready ? "Connected" : "Disconnected"}
                          </div>
                        );
                      }}
                    ></Column>
                  );
                })}
            </DataTable>
            {myNumbersOptions.length > 0 && (
              <div>
                <span>My default phone number: </span>
                <Dropdown
                  value={myNumberService.current?.number}
                  options={myNumbersOptions}
                  onChange={(e) => myNumberService.setCurrent(e.value)}
                />
              </div>
            )}
          </>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};
