import React, { useEffect, useRef, useState } from "react";
import { Loader } from "components/Loader/Loader";
import { generateGuid } from "helpers/guid";
import { useTemplateService } from "hooks/services/useTemplatesService";
import { Template } from "models/Template";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Splitter, SplitterPanel } from "primereact/splitter";
import { GoPlus } from "react-icons/go";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Search } from "components/Search/Search";
import { useSearch } from "hooks/useSearch";
import "./Templates.scss";
import { useFocus } from "hooks/useFocus";

export const SmsTemplates: React.FC = () => {
  const templateService = useTemplateService();
  const templates = templateService.templates;

  const [searchPattern, setSearchPattern] = useState("");
  const [currentTemplate, setCurrentTemplate] = useState<Template | undefined>(
    undefined
  );
  const [removeTemplatePopupVisible, setRemoveTemplatePopupVisible] =
    useState(false);

  const filteredTemplates = useSearch({
    items: templateService.templates,
    searchPattern,
    fields: ["name", "body"],
  });

  const templateListRef = useRef<HTMLUListElement>(null);
  const [focus, templateNameRef] = useFocus<HTMLInputElement>();
  useEffect(() => {
    const newTemplate = templates.find((t) => !t.name && !t.body);
    if (newTemplate) {
      setCurrentTemplate(newTemplate);
      setTimeout(() => {
        templateListRef.current
          ?.querySelector(".selected")
          ?.scrollIntoView({ behavior: "smooth", block: "center" });
        focus();
      }, 100);
    }
  }, [focus, templates, setCurrentTemplate, templateService.updates]);

  const addNewTemplate = async () => {
    templates.push({ id: generateGuid(), name: "", body: "" });
    templateService.update();
  };

  const removeTemplate = () => {
    const index = templates.findIndex((t) => t.id === currentTemplate?.id);
    if (index === -1) {
      return;
    }
    if (!removeTemplatePopupVisible) {
      setRemoveTemplatePopupVisible(true);
      return;
    }
    templates.splice(index, 1);
    templateService.update();
    setCurrentTemplate(undefined);
  };

  const saveTemplate = () => {
    const template = templateService.templates.find(
      (t) => t.id === currentTemplate?.id
    );
    if (!template || !currentTemplate) {
      return;
    }
    template.name = currentTemplate.name;
    template.body = currentTemplate.body;
    templateService.update();
  };

  console.log("templateService.isReady", templateService.isReady);

  if (!templateService.isReady) {
    return <Loader />;
  }

  return (
    <div className="sms-templates-component auto-flex">
      <Splitter>
        <SplitterPanel size={20} className="templates-panel auto-flex">
          <Search
            searchPattern={searchPattern}
            setSearchPattern={setSearchPattern}
          />
          {!templateService.isReady && <Loader />}
          <ul
            className="templates"
            onClick={() => setCurrentTemplate(undefined)}
            ref={templateListRef}
          >
            {filteredTemplates.map((template) => {
              return (
                <li
                  className={`template ${
                    currentTemplate?.id === template.id && "selected"
                  }`}
                  key={template.id}
                  onClick={(e) => {
                    setCurrentTemplate(template);
                    e.stopPropagation();
                  }}
                >
                  <div className="name">
                    {template.name}
                    {!template.name && <em>New template</em>}
                  </div>
                  <div className="description">{template.body}</div>
                </li>
              );
            })}
            {filteredTemplates.length === 0 ? (
              <em>No templates found</em>
            ) : null}
          </ul>
          {templates.every((t) => t.name) && (
            <Button
              className="p-button-rounded new-template-btn"
              onClick={addNewTemplate}
            >
              <GoPlus />
            </Button>
          )}
        </SplitterPanel>
        <SplitterPanel size={20} className="auto-flex">
          {currentTemplate && (
            <div className="template-details">
              <div className="p-field">
                <label htmlFor="apeiron-webphone-template-name-field">
                  Template name
                </label>
                <InputText
                  ref={templateNameRef}
                  id="apeiron-webphone-template-name-field"
                  value={currentTemplate.name}
                  onChange={(e) =>
                    setCurrentTemplate({
                      ...currentTemplate,
                      name: e.target.value,
                    })
                  }
                  onBlur={() => saveTemplate()}
                />
              </div>
              <div className="p-field">
                <label htmlFor="apeiron-webphone-template-message-field">
                  Template message
                </label>
                <InputTextarea
                  id="apeiron-webphone-template-message-field"
                  placeholder="Message text"
                  rows={2}
                  className="input-body"
                  value={currentTemplate.body}
                  onChange={(e) =>
                    setCurrentTemplate({
                      ...currentTemplate,
                      body: e.target.value,
                    })
                  }
                  onBlur={() => saveTemplate()}
                  autoResize
                />
              </div>
              <button
                className="p-button p-button-danger"
                onClick={removeTemplate}
              >
                Remove template
              </button>
              <ConfirmDialog
                visible={removeTemplatePopupVisible}
                onHide={() => setRemoveTemplatePopupVisible(false)}
                message="Are you sure you want to delete the template?"
                header="Delete Confirmation"
                icon="pi pi-exclamation-triangle"
                accept={removeTemplate}
                reject={() => setRemoveTemplatePopupVisible(false)}
              />
            </div>
          )}
        </SplitterPanel>
      </Splitter>
    </div>
  );
};
