import { ApiService, useApiService } from "./useApiService";
import { Contact } from "models/Contact";
import { storageKeys } from "const/storage-keys";
import { getStorageItem, setStorageItem } from "helpers/storage";
import * as _ from "lodash-es";
import { ContactData, SmsList } from "models/SmsList";
import { urls } from "const/urls";
import { AuthService, useAuthService } from "./useAuthService";
import { showToast } from "helpers/toast";
import { ContactService, useContactService } from "./useContactService";
import { delay } from "helpers/delay";
import { useService, Service, UpdateDispatcher } from "./useService";

const ud: UpdateDispatcher = new Set();

export function useSmsListService(): SmsListService {
  const api = useApiService();
  const auth = useAuthService();
  const contactService = useContactService();
  return useService(ud, SmsListService, { api, auth, contactService });
}

interface SmsListResponse {
  count: number;
  next: string;
  results: Array<{
    list_id: number;
    label: string;
  }>;
}
export class SmsListService extends Service {
  public static serviceName = "SmsListService";

  private _smsLists: SmsList[] =
    getStorageItem<SmsList[]>(storageKeys.sms.smsLists) || [];
  private _currentSmsList: SmsList | undefined; // must be one of this.smsLists or undefined
  private _smsListsUpdated = false;
  private smsListsLoadingCount = 0;
  private fieldsLoadingCount = 0;
  private updateFieldValueLock = false;
  private loadSmsListPromise: Promise<void> | null = null;

  private readonly api: ApiService;
  private readonly auth: AuthService;
  private readonly contactService: ContactService;

  constructor({
    api,
    auth,
    contactService,
  }: {
    api: ApiService;
    auth: AuthService;
    contactService: ContactService;
  }) {
    super({ api, auth, contactService });
    this.api = api;
    this.auth = auth;
    this.contactService = contactService;
  }

  public update() {
    setStorageItem(storageKeys.sms.smsLists, this.smsLists);
    super.update();
  }

  public async init(): Promise<void> {
    if (
      this.auth.authenticated &&
      !this.auth.callRecording &&
      !this.auth.callForwarding &&
      !this.auth.voicemail &&
      !this.auth.fax
    ) {
      this.loadSmsLists();
    }
    this.auth.onLoginHandlers.push(this.loadSmsLists.bind(this));
    this.auth.onLogoutHandlers.push(() => {
      this._smsLists = [];
      this._currentSmsList = undefined;
      this._smsListsUpdated = false;
      this.smsListsLoadingCount = 0;
      this.fieldsLoadingCount = 0;
      this.updateFieldValueLock = false;
      this.loadSmsListPromise = null;
    });
  }

  public get smsListsLoading(): boolean {
    return this.smsListsLoadingCount > 0;
  }

  public get fieldsLoading(): boolean {
    return this.fieldsLoadingCount > 0;
  }

  public async loadSmsLists(force = false): Promise<void> {
    if (this.auth.callRecording || this.auth.callForwarding) {
      return;
    }
    if (force) {
      this.loadSmsListPromise = null;
    }
    if (!this.loadSmsListPromise) {
      this.loadSmsListPromise = this._loadSmsLists();
    }
    return this.loadSmsListPromise;
  }

  private async _loadSmsLists(): Promise<void> {
    this.smsListsLoadingCount++;
    this.update();
    const { customerNumber } = this.auth.account;
    try {
      const firstPageUrl = urls.smsLists.replace(
        ":customerNumber",
        String(customerNumber)
      );
      let url = firstPageUrl;
      const lists: SmsList[] = [];
      do {
        const response = await this.api.get<SmsListResponse>(url);
        lists.push(
          ...response.results.map((x) => ({
            id: x.list_id,
            name: x.label,
            variables: [],
            contactData: [],
          }))
        );
        url = response.next || "";
      } while (url);
      await Promise.all([
        (async () => {
          const listVariables = await Promise.all(
            lists.map((list) =>
              this.api.get<string[]>(
                urls.smsListVariables.replace(":listId", list.id.toString())
              )
            )
          );
          for (let i = 0; i < lists.length; i++) {
            lists[i].variables = listVariables[i];
          }
        })(),
        (async () => {
          const details = await Promise.all(
            lists.map((list) =>
              this.api.get<{
                numbers: Array<{ number: string; [variable: string]: string }>;
              }>(urls.smsListDetails.replace(":listId", list.id.toString()))
            )
          );
          for (let i = 0; i < lists.length; i++) {
            lists[i].contactData = details[i].numbers.map((n) => {
              const fields: Record<string, string> = { ...n };
              delete fields.number;
              return {
                number: n.number,
                fields,
              };
            });
          }

          const newNumbers = _.difference(
            _.uniq(
              _.flatten(
                lists.map((list) => list.contactData.map((cd) => cd.number))
              )
            ),
            _.flatten(this.contactService.contacts.map((c) => c.numbers))
          );
          if (newNumbers.length) {
            for (const number of newNumbers) {
              this.contactService.addNewContact(number);
            }
          }
        })(),
      ]);

      this._smsLists = lists;
      setStorageItem(storageKeys.sms.smsLists, this._smsLists);

      this._smsListsUpdated = true;
    } finally {
      this.smsListsLoadingCount--;
      this.update();
    }
  }

  public get smsLists(): SmsList[] {
    return this._smsLists;
  }

  public get currentSmsList(): SmsList | undefined {
    return this._currentSmsList;
  }
  public set currentSmsList(value: SmsList | undefined) {
    this._currentSmsList = this._smsLists.find((c) => c.id === value?.id);
    this.update();
  }

  public get smsListsUpdated(): boolean {
    return this._smsListsUpdated;
  }

  public async addNewSmsList(name: string): Promise<SmsList | undefined> {
    const smsList: SmsList = {
      id: 0,
      name: name.trim(),
      variables: [],
      contactData: [],
    };
    this.smsListsLoadingCount++;
    this.update();
    try {
      try {
        smsList.id = (
          await this.api.post<{ list_id: number }>(urls.createSmsList, {
            label: smsList.name,
            customer_number: this.auth.account.customerNumber,
          })
        ).list_id;
      } catch (e) {
        console.error(e);
        showToast({
          severity: "error",
          summary: "An error occurred",
          detail: "Cannot create the SMS list",
        });
        return;
      }
      this.smsLists.push(smsList);
    } finally {
      this.smsListsLoadingCount--;
      this.update();
    }
    return smsList;
  }

  public async addContactToList(number: string, list: SmsList) {
    number = number.trim();
    if (!number) {
      return;
    }
    this.smsListsLoadingCount++;
    this.update();
    try {
      list.contactData.push({ number, fields: {} });
      await this.api.post(
        urls.addSmsListNumber.replace(":listId", list.id.toString()),
        {
          number,
          meta_data: JSON.stringify(
            _.fromPairs(list.variables.map((v) => [v, ""]))
          ),
        }
      );
    } finally {
      this.smsListsLoadingCount--;
      this.update();
    }
  }

  public async removeContactFromList(contact: Contact, list: SmsList) {
    this.smsListsLoadingCount++;
    this.update();
    try {
      const numbers = list.contactData
        .filter((cd) => contact.numbers.includes(cd.number))
        .map((cd) => cd.number);
      list.contactData = list.contactData.filter(
        (cd) => !numbers.includes(cd.number)
      );
      await Promise.all(
        numbers.map((number) =>
          this.api.delete(
            urls.removeSmsListNumber
              .replace(":listId", list.id.toString())
              .replace(":number", number)
          )
        )
      );
    } finally {
      this.smsListsLoadingCount--;
      this.update();
    }
  }

  public async updateFieldValue(
    list: SmsList,
    contactData: ContactData,
    variable: string,
    value: string
  ) {
    if ((contactData.fields[variable] || "") === (value || "")) {
      return;
    }
    this.fieldsLoadingCount++;
    this.update();
    while (this.updateFieldValueLock) {
      await delay(40);
    }
    this.updateFieldValueLock = true;
    try {
      contactData.fields[variable] = value;
      await this.api.delete(
        urls.removeSmsListNumber
          .replace(":listId", list.id.toString())
          .replace(":number", contactData.number)
      );
      await this.api.post(
        urls.addSmsListNumber.replace(":listId", list.id.toString()),
        {
          number: contactData.number,
          meta_data: JSON.stringify(contactData.fields),
        }
      );
    } finally {
      this.fieldsLoadingCount--;
      this.update();
      this.updateFieldValueLock = false;
    }
  }

  public async addNewField(newFieldName: string) {
    newFieldName = (newFieldName || "").trim();
    const list = this.currentSmsList;
    if (!newFieldName || !list) {
      return;
    }
    if (
      list.variables.some((f) => f.toLowerCase() === newFieldName.toLowerCase())
    ) {
      return;
    }
    list.variables.push(newFieldName);
    for (const cd of list.contactData) {
      cd.fields[newFieldName] = "";
    }
    this.fieldsLoadingCount++;
    this.update();
    while (this.updateFieldValueLock) {
      await delay(40);
    }
    this.updateFieldValueLock = true;
    try {
      await this.api.post(
        urls.addSmsListVariable.replace(":listId", list.id.toString()),
        { variable: newFieldName }
      );
    } finally {
      this.fieldsLoadingCount--;
      this.update();
      this.updateFieldValueLock = false;
    }
  }
}
