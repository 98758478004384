import React, { useCallback, useEffect } from "react";
import { useNavigationService } from "hooks/services/useNavigationService";
import { AppMode, messageTypesByPage, modeInfo } from "models/navigation";
import "./MainMenu.sass";
import { MessageType, allMessageTypes } from "models/Message";
import * as _ from "lodash-es";
import { useChatService } from "hooks/services/useChatService";
import { useAuthService } from "hooks/services/useAuthService";

export const MainMenu: React.FC = () => {
  const navigation = useNavigationService();
  const chatService = useChatService();
  const authService = useAuthService();

  const { mode, modes } = navigation;

  const selectMode = useCallback(
    (value: AppMode) => () => {
      navigation.mode = value;
    },
    [navigation]
  );

  const [unreadCountByMessageType, setUnreadCountByMessageType] =
    React.useState<Record<MessageType, number>>({
      [MessageType.call]: 0,
      [MessageType.sms]: 0,
      [MessageType.mms]: 0,
      [MessageType.fax]: 0,
      [MessageType.voicemail]: 0,
      [MessageType.callRecording]: 0,
    });

  useEffect(() => {
    if (authService.updates === 0) {
      // it's never zero, just use variable to trigger updates everytime when custom.readAt changes
      return;
    }
    (async () => {
      setUnreadCountByMessageType(
        _.fromPairs(
          (await Promise.all(
            allMessageTypes.map((type) =>
              chatService
                .getUnreadCount({ messageTypes: [type] })
                .then((count) => [type, count])
            )
          )) as [MessageType, number][]
        ) as Record<MessageType, number>
      );
    })();
  }, [chatService, authService.updates]);

  if (modes.length <= 1) {
    return null;
  }

  return (
    <div className="main-menu-component">
      <ul className="main-menu">
        {modes.map((m) => {
          const messageTypes = _.uniq(
            navigation.pagesByMode[m]?.flatMap((page) =>
              messageTypesByPage(page)
            )
          );
          const unreadMessages = messageTypes
            .map((type) => unreadCountByMessageType[type])
            .reduce((a, b) => a + b, 0);
          const { icon: Icon, name } = modeInfo[m];
          return (
            <li
              key={m}
              className={`mode-btn ${m === mode && "active"}`}
              onClick={selectMode(m)}
            >
              <Icon />
              <div>{name}</div>
              {unreadMessages > 0 && (
                <div className="unread-messages">{unreadMessages}</div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
