import { storageKeys } from "const/storage-keys";
import { ApiService, useApiService } from "./useApiService";
import { getStorageItem, setStorageItem } from "helpers/storage";
import { urls } from "const/urls";
import { Id, PhoneNumber } from "models/common";
import { MediaService, useMediaService } from "./useMediaService";
import { Direction, Message, MessageType } from "models/Message";
import { ContactService, useContactService } from "./useContactService";
import { MyNumberService, useMyNumberService } from "./useMyNumberService";
import { Feature } from "models/MyNumber";
import { generateGuid } from "helpers/guid";
import { AudioService, useAudioService } from "./useAudioService";
import * as _ from "lodash-es";
import { showToast } from "helpers/toast";
import { formatPhoneNumber } from "helpers/phone";
import { DraftService, useDraftService } from "./useDraftService";
import { Media, MediaType } from "models/Media";
import { AuthService, useAuthService } from "./useAuthService";
import { BrandService, useBrandService } from "./useBrandService";
import { delay } from "helpers/delay";
import { useService, Service, UpdateDispatcher } from "./useService";

const ud: UpdateDispatcher = new Set();

export function useMmsService(): MmsService {
  const api = useApiService();
  const auth = useAuthService();
  const audio = useAudioService();
  const myNumberService = useMyNumberService();
  const contactService = useContactService();
  const mediaService = useMediaService();
  const draftService = useDraftService();
  const brandService = useBrandService();
  return useService(ud, MmsService, {
    api,
    auth,
    audio,
    myNumberService,
    contactService,
    mediaService,
    draftService,
    brandService,
  });
}

export class MmsService extends Service {
  public static serviceName = "MmsService";

  private mmsHistory: Record<Id, Message[]> =
    getStorageItem(storageKeys.mms.messages) || {}; // key is contactId; messages are sorted `at desc`

  // WARNING: don't forget to care about data index integrity!
  private messageById: Record<Id, Message> = _.fromPairs(
    _.flatten(Object.values(this.mmsHistory))
      .filter((m) => m.entityId)
      .map((m) => [m.entityId, m])
  );
  private messagesByNumbers: Record<Id, Message[]> = // key is [myNumber, contactNumber].join('-')
    _.groupBy(
      _.flatten(Object.values(this.mmsHistory)),
      (m: Message) => `${m.myNumber}-${m.contactNumber}`
    );
  private myMmsPhoneNumbers: PhoneNumber[] = [];
  private onLoginHandler: Promise<void> | undefined;

  private ws: WebSocket | undefined;

  private readonly api: ApiService;
  private readonly auth: AuthService;
  private readonly audio: AudioService;
  private readonly myNumberService: MyNumberService;
  private readonly contactService: ContactService;
  private readonly mediaService: MediaService;
  private readonly draftService: DraftService;
  private readonly brandService: BrandService;

  constructor({
    api,
    auth,
    audio,
    myNumberService,
    contactService,
    mediaService,
    draftService,
    brandService,
  }: {
    api: ApiService;
    auth: AuthService;
    audio: AudioService;
    myNumberService: MyNumberService;
    contactService: ContactService;
    mediaService: MediaService;
    draftService: DraftService;
    brandService: BrandService;
  }) {
    super({
      api,
      auth,
      audio,
      myNumberService,
      contactService,
      mediaService,
      draftService,
      brandService,
    });
    this.api = api;
    this.auth = auth;
    this.audio = audio;
    this.myNumberService = myNumberService;
    this.contactService = contactService;
    this.mediaService = mediaService;
    this.draftService = draftService;
    this.brandService = brandService;
  }

  public update() {
    setStorageItem(storageKeys.mms.messages, this.mmsHistory);
    super.update();
  }

  public async init() {
    if (this.auth.authenticated) {
      this.onLoginHandler = this.onLogin();
    }
    this.auth.onLoginHandlers.push(
      () => (this.onLoginHandler = this.onLoginHandler || this.onLogin())
    );
    this.auth.onLogoutHandlers.push(() => {
      this.mmsHistory = {};
      this.messageById = {};
      this.messagesByNumbers = {};
      this.myMmsPhoneNumbers = [];
      this.onLoginHandler = undefined;
      try {
        this.ws?.close();
      } catch (e) {
        console.error(e);
      }
      this.ws = undefined;
    });
    if (this.auth.authenticated) {
      await this.onLoginHandler;
    }
  }

  private async onLogin() {
    if (
      this.auth.callRecording ||
      this.auth.callForwarding ||
      this.auth.voicemail ||
      this.auth.fax
    ) {
      return;
    }
    this.initWebSocket();
    this.onDependentServiceUpdate({ serviceName: MyNumberService.name });
  }

  public async onDependentServiceUpdate({
    serviceName,
  }: {
    serviceName: string;
  }) {
    switch (serviceName) {
      case MyNumberService.name: {
        if (this.auth.callRecording || this.auth.callForwarding) {
          return;
        }
        const myMmsPhoneNumbers = this.myNumberService.myNumbers.filter((n) =>
          n.features.includes(Feature.mms)
        );
        const newPhoneNumbers = myMmsPhoneNumbers
          .filter((n) => !this.myMmsPhoneNumbers.includes(n.number))
          .map((n) => n.number);
        this.myMmsPhoneNumbers = myMmsPhoneNumbers.map((n) => n.number);
        if (!newPhoneNumbers.length) {
          break;
        }
        await Promise.all(
          newPhoneNumbers.map((myNumber) =>
            this.loadMessagesByMyNumber(myNumber)
          )
        );
        this.myNumberService.myNumbers
          .filter((myNumber) => newPhoneNumbers.includes(myNumber.number))
          .forEach((myNumber) => {
            myNumber.mms!.ready = true;
          });
        this.myNumberService.update();
        this.update();
        break;
      }
    }
  }

  public getMessagesByContactId(contactId: Id): Message[] {
    return this.mmsHistory[contactId] || [];
  }

  public getAllMessages(): Message[] {
    return _.sortBy(_.flatten(Object.values(this.mmsHistory)), "at", "desc");
  }

  public getContactIdByMedia(mediaId: Id): Id | undefined {
    if (!mediaId) {
      return undefined;
    }
    return _.toPairs(this.mmsHistory)
      .filter(([, messages]) => messages.some((m) => m.mediaId === mediaId))
      .map(([contactId]) => contactId)[0];
  }

  private async loadMessagesByMyNumber(myNumber: string): Promise<void> {
    interface Response {
      count: number;
      next?: string;
      results: Array<{
        date_received?: string; // "2019-08-24T14:15:22Z", // date_received is null for outbound messages
        date_sent?: string; // "2019-08-24T14:15:22Z",     // date_sent is null for inbound messages
        message_id: string; // "\"1696327233@1-mms1.mmsnni.mgw.iqntusa.net\":2"
        from_number: string;
        to_number: string;
        priority: number;
        coding: number;
        validity: string; // "2019-08-24T14:15:22Z",
        content: string; // text content, if any, otherwise null
        attachment_url: string; // URL, if a file attached
        attachment_mime_type: string;
        attachment_file_name: string;
      }>;
    }
    const directions = [
      {
        direction: Direction.in,
        url: urls.inboundMmsMessages,
        countStorage: storageKeys.mms.countIn,
      },
      {
        direction: Direction.out,
        url: urls.outboundMmsMessages,
        countStorage: storageKeys.mms.countOut,
      },
    ];
    for (const direction of directions) {
      const firstPageUrl = direction.url.replace(":myNumber", myNumber);
      const countKey = direction.countStorage.replace(":myNumber", myNumber);
      let url = firstPageUrl;
      do {
        const response = await this.api.get<Response>(url);
        if (url === firstPageUrl) {
          // page 1
          if (response.count === getStorageItem<number>(countKey)) {
            break;
          }
          setStorageItem(countKey, response.count || 0);
        }

        // update corrupted cached MMS
        response.results.forEach((x) => {
          const mediaId = this.messageById[x.message_id]?.mediaId;
          if (!mediaId) {
            return;
          }
          let media = this.mediaService.getMediaById(mediaId);
          if (
            media &&
            media.url === x.attachment_url &&
            media.mimeType === x.attachment_mime_type &&
            media.fileName === x.attachment_file_name
          ) {
            return;
          }
          media = {
            ...media,
            id: media?.id || x.message_id,
            url: x.attachment_url,
            mimeType: x.attachment_mime_type,
            fileName: x.attachment_file_name,
            type: MediaType.MMS,
          };
          this.mediaService.updateMedia(media);
        });

        // add new MMS
        const newMms: Message[] = response.results
          .filter((x) => !this.messageById[x.message_id])
          .map((x) => {
            const at = new Date((x.date_received || x.date_sent)!).getTime();
            const media =
              this.mediaService.getMediaByUrl(x.attachment_url) ||
              this.mediaService.addNewMedia({
                id: x.message_id,
                url: x.attachment_url,
                mimeType: x.attachment_mime_type,
                fileName: x.attachment_file_name,
                type: MediaType.MMS,
              });
            const myNumber =
              direction.direction === Direction.in
                ? x.to_number
                : x.from_number;
            const contactNumber =
              direction.direction === Direction.in
                ? x.from_number
                : x.to_number;
            return {
              myNumber,
              contactNumber,
              direction: direction.direction,
              type: MessageType.mms,
              entityId: x.message_id,
              at,
              body: x.content || "",
              sentAt: at,
              mediaId: media.id,
            } as Message;
          });
        for (const mms of newMms) {
          this.addNewMms(mms);
        }
        url = response.next || "";
      } while (url);
    }
    this.update();
  }

  private initWebSocket() {
    const accessToken = this.auth.token?.accessToken;
    if (!accessToken) {
      return;
    }
    interface WebSocketMessage {
      jsonrpc: "2.0";
      data: {
        type: "event_sms_received" | "event_sms_sent";
        from: string;
        to: string;
        mms: boolean;
      };
    }
    this.ws = new WebSocket(
      this.brandService.brand.smsWebSocket.replace(
        "{ACCESS_TOKEN}",
        accessToken
      )
    );
    this.ws.onopen = () => {
      console.log("MMS webSocket opened");

      this.ws!.onmessage = async (event: { data: string }) => {
        const webSocketMessage = JSON.parse(event.data) as WebSocketMessage;
        const { data } = webSocketMessage;
        console.log("WebSocket INCOMING MMS MESSAGE", webSocketMessage);
        if (!data.mms) {
          return;
        }
        const direction =
          data.type === "event_sms_received"
            ? Direction.in
            : data.type === "event_sms_sent"
            ? Direction.out
            : "";
        if (!direction) {
          return;
        }
        if (direction === Direction.in) {
          this.audio.play("incomingSms");
        }
        const myNumber = data.to;
        const contactNumber = data.from;
        if (
          !this.myNumberService.myNumbers.find((n) => n.number === myNumber)
        ) {
          console.error(`My number ${myNumber} not found`);
          return;
        }
        if (direction === Direction.in) {
          showToast({
            severity: "info",
            summary: `MMS from ${formatPhoneNumber(contactNumber)}`,
            detail: "",
          });
        }
        await this.loadMessagesByMyNumber(myNumber);
      };

      this.ws!.onerror = (event) =>
        console.log("On MMS WebSocket error", event);
      this.ws!.onclose = (event) =>
        console.log("On MMS WebSocket close", event);
    };
  }

  private async addNewMms(message: Message) {
    const { myNumber, contactNumber } = message;
    if (message.type && message.type !== MessageType.mms) {
      throw new Error(
        `Cannot add MMS message with messageType ${message.type}`
      );
    }
    if (!myNumber || !contactNumber) {
      throw new Error(
        "myNumber and contactNumber should be provided for the new message"
      );
    }
    message.type = MessageType.mms;
    message.entityId = message.entityId || generateGuid();
    const contact =
      this.contactService.getContactByNumber(message.contactNumber) ||
      this.contactService.addNewContact(message.contactNumber, message);
    contact.hasMms = true;
    this.mmsHistory[contact.id] = this.mmsHistory[contact.id] || [];
    this.mmsHistory[contact.id].unshift(message);
    this.messageById[message.entityId] = message;
    const numbersKey = [message.myNumber, message.contactNumber].join("-");
    this.messagesByNumbers[numbersKey] =
      this.messagesByNumbers[numbersKey] || [];
    this.messagesByNumbers[numbersKey].push(message);
    this.update();
  }

  get totalMessageCount(): number {
    return _.flatten(Object.values(this.mmsHistory)).length;
  }

  public async sendDraftMessage(
    contactId: Id = this.contactService.current?.id || ""
  ): Promise<Message> {
    const message = this.draftService.getDraftMessage(contactId);
    if (!message) {
      throw new Error(`message draft not found`);
    }
    if (!message?.body?.trim() && !message.mediaId) {
      throw new Error(`message or attachment is required to send the message`);
    }
    message.at = new Date().getTime();
    this.addNewMms(message);
    this.draftService.deleteDraftMessage(contactId);
    await this.sendMessage(message);
    if (!contactId) {
      this.contactService.current = this.contactService.getContactByNumber(
        message.contactNumber
      );
    }
    return message;
  }

  public async sendMessage(message: Message): Promise<void> {
    if (!message.failed) {
      _.remove(this.auth.account.customData.failedMessages, message);
      this.auth.saveCustomData();
    }
    delete message.failed;
    message.sending = true;
    this.update();
    interface MessageSentResponse {
      message_id: string;
      sms_thread_id: number;
      date_sent: string;
      delivered: boolean;
      delivery_info: any;
      message?: string; // in case of fail
      attachment_url?: string;
    }
    const { myNumber, contactNumber } = message;
    let file: File | undefined = undefined;
    let media: Media | undefined = undefined;
    if (message.mediaId) {
      media = this.mediaService.getMediaById(message.mediaId)!;
      while (media.downloadingFileContent) {
        await delay(100);
      }
      file =
        media.file ||
        new File([media.fileContent!], media.fileName!, {
          type: media.mimeType,
        });
    }
    try {
      const response = await this.api.post<MessageSentResponse>(
        urls.sendMms.replace(":myNumber", myNumber),
        {},
        {
          form: {
            to_number: contactNumber,
            message: message.body || "",
            ...(file && { attachment: file }),
          },
        }
      );
      if (!response.message_id) {
        console.error(response);
        throw new Error(response.message);
      }
      console.log(response);
      message.entityId = response.message_id;
      message.at = message.sentAt = new Date(response.date_sent).getTime();
      if (media && response.attachment_url) {
        const newMedia = this.mediaService.addNewMedia({
          ...media,
          id: response.message_id,
          url: response.attachment_url,
        });
        this.mediaService.removeMedia(media.id);
        message.mediaId = newMedia.id;
      }
    } catch (e: any) {
      message.failed =
        e?.message && typeof e.message === "string" ? e.message : true;
    } finally {
      delete message.sending;
    }
    if (message.failed) {
      this.auth.account.customData.failedMessages.push(message);
      this.auth.saveCustomData();
    }
    this.update();
  }
}
