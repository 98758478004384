import React, { useEffect, useState } from "react";
import { useMmsService } from "hooks/services/useMmsService";
import { useContactService } from "hooks/services/useContactService";
import { useMediaService } from "hooks/services/useMediaService";
import { Galleria } from "primereact/galleria";
import { Media } from "models/Media";
import { Loader } from "components/Loader/Loader";
import { usePrevious } from "hooks/usePrevious";
import "./Gallery.scss";

export const Gallery: React.FC = () => {
  const mmsService = useMmsService();
  const mediaService = useMediaService();
  const contactService = useContactService();

  const [medias, setMedias] = useState<Media[]>([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const contactId = contactService.current?.id;

  useEffect(() => {
    if (mmsService.updates === -1) {
      return;
    }
    const mmsMessages = contactId
      ? mmsService.getMessagesByContactId(contactId)
      : mmsService.getAllMessages();
    const newMedias: Media[] = mmsMessages
      .map((message) => {
        const media = message.mediaId
          ? mediaService.getMediaById(message.mediaId)
          : undefined;
        if (!media || !(media.mimeType || "").startsWith("image")) {
          return null;
        }
        return media;
      })
      .filter(Boolean) as Media[];
    setMedias(newMedias);
  }, [mmsService.updates, contactId, mmsService, mediaService]);

  const itemTemplate = (media: Media) =>
    media && (
      <img src={media.url} alt={media.description} className="large-image" />
    );

  const thumbnailTemplate = (media: Media) =>
    media && (
      <img src={media.url} alt={media.description} className="thumbnail" />
    );

  const caption = (media: Media) => {
    const contactId = mmsService.getContactIdByMedia(media?.id);
    if (!contactId) {
      return null;
    }
    const contact = contactService.getContactById(contactId);
    if (!contact?.name) {
      return null;
    }
    const selectContact = () => (contactService.current = contact);
    return (
      <div className="contact-name" onClick={selectContact}>
        {contact.name}
      </div>
    );
  };

  const prevMedias = usePrevious(medias);
  useEffect(() => {
    if (!prevMedias || medias === prevMedias) {
      return;
    }
    const prevMediaId = prevMedias[activeIndex]?.id;
    const newIndex = Math.max(
      0,
      medias.findIndex((m) => m.id === prevMediaId)
    );
    setActiveIndex(newIndex);
  }, [medias, prevMedias, activeIndex, setActiveIndex]);

  return (
    <div className="gallery-component">
      {!mmsService.ready ? (
        <Loader />
      ) : !medias.length ? (
        <em>
          No images found.
          <br />
          Please send or recieve MMS with image to see it in the gallery.
        </em>
      ) : (
        <Galleria
          value={medias}
          numVisible={Math.min(medias.length, 5)}
          item={itemTemplate}
          thumbnail={thumbnailTemplate}
          caption={caption}
          activeIndex={activeIndex}
          onItemChange={(e) => setActiveIndex(e.index)}
        />
      )}
    </div>
  );
};
