import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Divider } from "primereact/divider";
import {
  MyNumberGreeting,
  useGreetingService,
} from "hooks/services/useGreetingService";
import { Loader } from "components/Loader/Loader";
import { Media, MediaType } from "models/Media";
import { useMediaService } from "hooks/services/useMediaService";
import { GreetingCard } from "./GreetingCard";
import { MyNumberGreetingCard } from "./MyNumberGreetingCard";
import "./Greetings.sass";
import { Search } from "components/Search/Search";
import { useSearch } from "hooks/useSearch";
import { Button } from "primereact/button";
import { GoPlus } from "react-icons/go";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useFocus } from "hooks/useFocus";
import useRecording from "hooks/useRecording";

export const Greetings: React.FC = () => {
  const greetingService = useGreetingService();
  const mediaService = useMediaService();

  const [numberGreetings, setNumberGreetings] = useState<
    MyNumberGreeting[] | undefined
  >();
  const [greetings, setGreetings] = useState<Media[] | undefined>();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [searchPattern, setSearchPattern] = useState("");
  const [newGreetingVisible, setNewGreetingVisible] = useState(false);
  const [newGreetingName, setNewGreetingName] = useState("");
  const [newGreetingFile, setNewGreetingFile] = useState<File | undefined>();
  const [recording, setRecording] = useState(false);

  const [startRecording, stopRecording, recordedFile] = useRecording();

  useEffect(() => {
    if (greetingService.isReady && !numberGreetings) {
      greetingService
        .loadMyNumberGreetings()
        .then((result) => setNumberGreetings(result));
    }
  }, [greetingService.isReady, greetingService, numberGreetings]);

  useEffect(() => {
    if (mediaService.isReady && !greetings) {
      setGreetings(mediaService.getMediasByType(MediaType.Greeting));
    }
  }, [mediaService.isReady, mediaService, greetings]);

  const setMyNumberGreeting = useCallback(
    async ({ myNumber, url }: { myNumber: string; url?: string }) => {
      const index = (numberGreetings || []).findIndex(
        (x) => x.myNumber.number === myNumber
      );
      const newNumberGreetings = numberGreetings || []; // [...(numberGreetings || [])];
      const greeting = url
        ? (greetings || []).find((g) => g.url === url)
        : undefined;
      newNumberGreetings.splice(index, 1, {
        myNumber: newNumberGreetings[index].myNumber,
        greeting,
      });
      setNumberGreetings(newNumberGreetings);
      setLoading(true);
      try {
        await greetingService.setMyNumberGreeting({
          myNumber,
          mediaId: greeting?.id,
        });
      } finally {
        setLoading(false);
      }
    },
    [numberGreetings, greetings, greetingService]
  );

  const hasGreetings = Boolean(greetings?.length);

  const filteredGreetings = useSearch({
    items: greetings || [],
    searchPattern,
    fields: ["description"],
  });

  const showNewGreetingDialog = () => setNewGreetingVisible(true);
  const hideNewGreetingDialog = () => setNewGreetingVisible(false);

  const [focus, greetingNameRef] = useFocus<HTMLInputElement>();
  useEffect(() => {
    if (newGreetingVisible) {
      focus();
    }
  }, [newGreetingVisible, focus]);

  const onFileUploadChange = async (
    event: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setNewGreetingFile(event.target.files?.[0]);
  };

  const submitNewGreeting = async () => {
    if (!newGreetingFile || !newGreetingName) {
      return;
    }
    setUploading(true);
    setNewGreetingVisible(false);
    try {
      console.log(newGreetingFile);
      await mediaService.uploadMedia({
        file: newGreetingFile,
        type: MediaType.Greeting,
        description: newGreetingName,
      });
      setNewGreetingName("");
      setNewGreetingFile(undefined);
      await mediaService.loadMedia();
      setGreetings(mediaService.getMediasByType(MediaType.Greeting));
    } finally {
      setTimeout(() => setUploading(false), 100);
    }
  };

  const onRecordClick = async (): Promise<void> => {
    if (!recording) {
      setNewGreetingFile(undefined);
      startRecording();
    } else {
      stopRecording();
    }
    setRecording(!recording);
  };

  useEffect(() => {
    if (recordedFile) {
      setNewGreetingFile(recordedFile);
    }
  }, [recordedFile]);

  if (!greetings || !numberGreetings) {
    return <Loader />;
  }

  const canSubmitNewGreeting = Boolean(newGreetingFile && newGreetingName);

  return (
    <div className="greetings-component auto-flex">
      {(loading || uploading) && <Loader />}
      <div className="greetings-panel">
        <div className="greetings auto-flex">
          {!uploading && (
            <Button
              className="p-button-rounded new-greeting-btn"
              onClick={showNewGreetingDialog}
              title="New greeting"
            >
              <GoPlus />
            </Button>
          )}

          {hasGreetings ? (
            <>
              <Search
                searchPattern={searchPattern}
                setSearchPattern={setSearchPattern}
              />
              <div className="greeting-cards auto-flex">
                {filteredGreetings.map((greeting) => (
                  <GreetingCard
                    key={greeting.id}
                    greeting={greeting}
                    setMyNumberGreeting={setMyNumberGreeting}
                  />
                ))}
              </div>
            </>
          ) : (
            <div className="no-greetings">No greetings found</div>
          )}
        </div>
        {hasGreetings && (
          <>
            <Divider layout="vertical" />
            <div className="numbers">
              <p>
                Please drag audio greetings over the phone number
                {numberGreetings.length > 1 && "s"}
              </p>
              {numberGreetings.map((myNumberGreeting) => (
                <MyNumberGreetingCard
                  key={myNumberGreeting.myNumber.number}
                  myNumberGreeting={myNumberGreeting}
                  unsetGreeting={setMyNumberGreeting}
                />
              ))}
            </div>
          </>
        )}
      </div>
      <Dialog
        header="New greeting"
        visible={newGreetingVisible}
        onHide={hideNewGreetingDialog}
      >
        <div className="greetings-flex-row">
          <div className="upload">
            <Button>
              Choose File{newGreetingFile && !recordedFile && " ✅"}
            </Button>
            <input
              type="file"
              name="file"
              accept=".mp3,.wav,audio/mp3,audio/*;capture=microphone"
              onChange={onFileUploadChange}
            />
          </div>
          <span>&nbsp;or&nbsp;</span>
          <div className="record">
            {!recording ? (
              <Button onClick={onRecordClick}>
                Record Audio {recordedFile && " ✅"}
              </Button>
            ) : (
              <Button className="p-button-danger" onClick={onRecordClick}>
                Stop Recording
              </Button>
            )}
          </div>
        </div>
        <div className="greetings-flex-row">
          <InputText
            ref={greetingNameRef}
            placeholder="Greeting name"
            value={newGreetingName}
            onChange={(e) => setNewGreetingName(e.currentTarget.value)}
          />
          <button
            className={`p-button submit-new-greeting-btn ${
              !canSubmitNewGreeting && "p-disabled"
            }`}
            onClick={submitNewGreeting}
            disabled={!canSubmitNewGreeting}
          >
            OK
          </button>
        </div>
      </Dialog>
    </div>
  );
};
