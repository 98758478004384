import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useMyNumberService } from "hooks/services/useMyNumberService";
import { Feature, features, MyNumber } from "models/MyNumber";
import { Loader } from "components/Loader/Loader";
import { showToast } from "helpers/toast";
import { useChatService } from "hooks/services/useChatService";
import { useNavigationService } from "hooks/services/useNavigationService";
import { delay } from "helpers/delay";
import { AppMode, Page } from "models/navigation";
import "./CallRecordingSettings.sass";

const featureClassPrefix = "feature-";

export const featureNavigation: Record<
  Feature,
  { name: string; mode: AppMode; page: Page }
> = {
  [Feature.calls]: {
    name: "Calls",
    mode: AppMode.calls,
    page: Page.callHistory,
  },
  [Feature.sms]: { name: "SMS", mode: AppMode.sms, page: Page.sms },
  [Feature.mms]: { name: "MMS", mode: AppMode.sms, page: Page.sms },
  [Feature.voicemails]: {
    name: "Voicemails",
    mode: AppMode.voicemails,
    page: Page.voicemails,
  },
  [Feature.fax]: { name: "Fax", mode: AppMode.fax, page: Page.fax },
  [Feature.callForwarding]: {
    name: "Call Forwarding",
    mode: AppMode.calls,
    page: Page.callForwarding,
  },
  [Feature.callRecording]: {
    name: "Call Recording",
    mode: AppMode.calls,
    page: Page.callRecordings,
  },
  [Feature.callProcedures]: {
    name: "Call Procedures",
    mode: AppMode.calls,
    page: Page.callProcedures,
  },
};

export const CallRecordingSettings: React.FC = () => {
  const navigation = useNavigationService();
  const myNumberService = useMyNumberService();
  const chatService = useChatService(); // includes callService, smsSerivce, voicemailService, mediaService, myNumberService, contactService

  const onRowClick = async (e: any) => {
    const classList: string[] = Array.from(
      (e.originalEvent.target as HTMLElement).classList
    );
    const feature = classList
      .find((c) => c.startsWith(featureClassPrefix))
      ?.substring(featureClassPrefix.length) as Feature;
    const myNumber = e.data as MyNumber;
    myNumberService.setCurrent(myNumber.number);
    if (!feature) {
      return;
    }
    if (!myNumber.features.includes(feature)) {
      showToast({
        severity: "warn",
        summary: "Disabled feature",
        detail: `Sorry, unfortunately ${featureNavigation[feature].name} feature is disabled for ${myNumber.name}.`,
      });
      return;
    }
    await delay(500);
    navigation.mode = featureNavigation[feature].mode;
    navigation.page = featureNavigation[feature].page;
  };

  return (
    <div className="call-recording-settings-page">
      <div className="number-features">
        {myNumberService.myNumbers.length > 0 ? (
          <>
            {!chatService.isReady && <Loader />}
            <DataTable
              value={myNumberService.myNumbers}
              onRowClick={onRowClick}
            >
              <Column
                field="number"
                header="Phone Number"
                body={(myNumber) => (
                  <div className="number">{myNumber.name}</div>
                )}
              ></Column>
              {features
                .filter((f) => myNumberService.hasFeature(f))
                .map((feature) => {
                  const featureClass = `${featureClassPrefix}${feature}`;
                  return (
                    <Column
                      key={feature}
                      field={feature}
                      header={featureNavigation[feature].name}
                      className={featureClass}
                      body={(myNumber: MyNumber) => (
                        <div>
                          {myNumber.features.includes(feature)
                            ? "Enabled"
                            : "Disabled"}
                        </div>
                      )}
                    ></Column>
                  );
                })}
            </DataTable>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};
