import { delay } from "helpers/delay";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

export type UpdateDispatcher = Set<Dispatch<SetStateAction<number>>>;

const services: Map<string, Service> = new Map(); // key is service class name, value is service instance

export function useUpdateDispatcher(updateDispatcher: UpdateDispatcher) {
  const [, setUpdates] = useState(0);
  useEffect(() => {
    if (!updateDispatcher) {
      return;
    }
    updateDispatcher.add(setUpdates);
    return () => {
      updateDispatcher.delete(setUpdates);
    };
  }, [updateDispatcher]);
}

export function useService<T extends Service, K, L>(
  updateDispatcher: UpdateDispatcher,
  type: { new (args: K): T },
  initializationParams?: K, // { dependentService1, dependentService2, ... }
  liveParams?: L // { variable1, variable2, ... }, where [variable1] = useState(initialValue1)
): T {
  // create service instance if it doesn't exist yet
  const serviceName = (type as any).serviceName;
  let service: T = services.get(serviceName) as T;
  if (!service) {
    service = new type(initializationParams!); // though we put ! here, it is safe, because we pass initializationParams only if they are defined
    services.set(serviceName, service);
  }

  service._updateDispatcher = updateDispatcher;
  useUpdateDispatcher(updateDispatcher);

  if (liveParams && Object.keys(liveParams).length > 0) {
    service.onChange(liveParams);
  }

  return service;
}

export class Service {
  public static serviceName = "Service";

  public ready: Promise<void>;
  public isReady = false;

  private _updates: number = 0;
  public _updateDispatcher: UpdateDispatcher | undefined; // for internal use only!

  public dependentServices: Service[] = [];
  private requiredServices: Service[] = [];

  // inject all dependent services and other hooks here as Dependency Injection
  constructor(dependencyInjection: any = {}) {
    this.requiredServices = Object.values(dependencyInjection || {}).filter(
      (arg) => arg instanceof Service
    ) as Service[];
    this.requiredServices.forEach((service) =>
      service.dependentServices.push(this)
    );
    this.ready = new Promise((resolve) =>
      (async () => {
        await delay(); // proceed with custom service initialization prior to init() method
        await Promise.all(
          this.requiredServices.map((service) => service.ready)
        );
        await this.init();
        this.update();
        this.isReady = true;
        this.internalUpdate();
        console.log(`${(this.constructor as any).serviceName} ready.`);
        resolve();
      })()
    );
  }

  // override this method to initialize the service, it's been triggerred only once, when all dependent services are ready
  public async init(): Promise<void> {}

  // override this method, if you want to store any data in localStorage etc., and then put super.update() at the end of your method
  public update() {
    this.internalUpdate();
  }

  // override this method, if you want to handle dependant services updates
  public onDependentServiceUpdate({ serviceName: _ }: { serviceName: string }) {
    // this.update();
  }

  // override it, it calls every time when any of liveParams changed
  // @ts-expect-error
  public onChange(liveParams: any) {}

  public get updates(): number {
    return this._updates;
  }

  private internalUpdate() {
    this._updates++;
    const updateDispatcher = this.getUpdateDispatcher();
    for (const setUpdate of Array.from(updateDispatcher || [])) {
      setUpdate(this._updates);
    }
    for (const service of this.dependentServices) {
      service.onDependentServiceUpdate({
        serviceName: (this.constructor as any).serviceName,
      });
    }
  }

  // override it
  public getUpdateDispatcher(): UpdateDispatcher | undefined {
    return this._updateDispatcher;
  }
}

/*
  Usage:

  Write own service file, let say hooks/services/useMyService.ts:


  export function useMyService(): MyService {
    const otherService = useOtherService(); // initialize all dependent services and other hooks here
    return useService(MyService, { otherService });
  }

  class MyService extends Service {

    public value1 = localStorage.getItem('value1') || '';

    protected otherService: OtherService;
    
    async constructor({ otherService }: { otherService: OtherService }}) {
      super({ otherService });
      this.otherService = otherService;
    }

    public update() {
      localStorage.setItem('value1', this.value1);
      super.update();
    }

    public async init(): Promise<void> {
      await otherService.ready;
    }

    async myMethod() {
      await this.ready;
      this.value1 = this.otherService.value2;
      this.update(); // this stores the value1 in localStorage and triggers re-render of all components that use this service
    }
  }

  

  Now you can use it in your components like this:

  const myService = useMyService();

  // Now you can use all methods and properties of myService.
  // Every time when something changes inside myService and it calls its internal update(),
  // your component will be re-rendered and will use latest value of myService,
  // like it happens when you use useState().

*/
